import router from './router'
import Store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Vue from 'vue';
const vm = new Vue()
NProgress.configure({ showSpinner: false })

let  overdueTime = process.env.VUE_APP_TOKEN_OVERDUETIME

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  let accountAddress  = Store.getters.account || localStorage.getItem('account')
  let storeAccountToken =  JSON.parse(localStorage.getItem('storeAccountToken'))
 if(storeAccountToken && storeAccountToken[accountAddress]){
      const curTokenTimestamp = vm.$dayjs.utc().local().valueOf()
      const newUtcTimestamp = storeAccountToken && storeAccountToken[accountAddress]&& Number(storeAccountToken[accountAddress].startTime) + parseInt(+overdueTime/2)*60*1000
       
        const  overdueTimeUtcTimestamp = storeAccountToken && storeAccountToken[accountAddress]&& Number(storeAccountToken[accountAddress].startTime) + parseInt(+overdueTime)*60*1000
     
       
      if(curTokenTimestamp>newUtcTimestamp && curTokenTimestamp<overdueTimeUtcTimestamp){ // 
       Store.dispatch('app/refreshToken').then(()=>{
          next() 
          NProgress.done()
        }).catch(err=>{
          console.log(err,'err=====>>>>>NProgress')
          next() 
          NProgress.done()
        })
        next() 
        NProgress.done()
      }else{
        next() 
        NProgress.done()
      }
  }else{
    next() 
    NProgress.done()
  }
  next() 
  NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
})
