
const user = {
  state: {

    addressTo: '',
    isLoading: false,

  },
  actions: {

    setIsLoading({commit}, status) {
      //console.log("SET_IS_LOADING")
      commit('SET_IS_LOADING', status)
    },
  },
  mutations: {
    SET_IS_LOADING: (state, status) => {
      state.isLoading = status
    },

  }
}

export default user

