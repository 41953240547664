<template>
    <div id="app" ref="app" v-resize="resize">
        <div class="home-app">
            <!--      <nav  v-if="!isMobile">-->
            <!--      <img class="logo" src="@/assets/logo.png" alt="">-->
            <!--      <div class="nav_div">-->
            <!--        <router-link to="/">{{ $t("menu.Home") }}</router-link>-->
            <!--        <router-link to="/cardPurchase">{{ $t("menu.CardPurchase") }}</router-link>-->
            <!--        <router-link  to="/personal">{{ $t("menu.PersonalCenter") }}</router-link>-->

            <!--      </div>-->
            <!--    </nav>-->
            <div class="mobile-nav">
                <div class="header-container-nav">
                    <router-link :to="{name:'exchange'}" class="logo-text">
                        Infinimos
                        <!-- <img src="@/assets/logo.png" class="logo" alt=""> -->
                    </router-link>
                    <div class="flex-content flex-content-pc" v-if="!isMobile">
                        <div class="nav-cell-wrap nav-cell-wrap-pc">
                            <router-link to="/exchange" class="home-link text-gray" id="home-link" ref="myElement">
                                {{ $t("menu.Exchange") }}
                            </router-link>
                            <router-link to="/ftoken" class="home-exchange text-gray" >
                                {{ $t("menu.fTokenPool") }}
                            </router-link>
                            <router-link to="/infi" class="home-liquid text-gray" >
                                {{ $t("menu.infiPool") }}
                            </router-link>
                            <router-link to="/airdrop" class="home-liquid text-gray" >
                                {{ $t("menu.airDrop") }}
                            </router-link>
                            <a target="_blank" href="https://infinimos-organization.gitbook.io/infinimos-v-1.0-introduction/" class="whitePaper">  {{ $t("menu.whitePaper") }} </a>
                            <span class="menu-line"></span>
                        </div>
                    </div>
                    <div class="right">
                        <div class="language" @click="toggleLangNav" v-if="false">
                            <!-- <button v-if="language == 'en' ">EN</button> -->
                            <button v-if="language == 'zh' ">CN</button>
                            <!-- <button v-if="language == 'jp' ">JP</button>
                            <button v-if="language == 'kr' ">KR</button>
                            <button v-if="language == 'fr' ">FR</button> -->
                            <img src="@/assets/down-white.svg" alt="" class="down">
                            <div class="languageWraper" v-if="langMenuShow">
                                <div class="drawer-model"
                                     @click="toggleLangNav"></div>
                                <div class="langList" v-if="langMenuShow">
                                    <!-- <div :class="language == 'en' ? 'lanItem lanItemHover':'lanItem'" @click="changeHandle('en')">
                                        English
                                    </div> -->
                                    <div :class="language == 'zh' ? 'lanItem lanItemHover':'lanItem'" @click="changeHandle('zh')">
                                        中文
                                    </div>
                                    <!-- <div :class="language == 'jp' ? 'lanItem lanItemHover':'lanItem'" @click="changeHandle('jp')">
                                        日本語
                                    </div>
                                    <div :class="language == 'kr' ? 'lanItem lanItemHover':'lanItem'" @click="changeHandle('kr')">
                                        한국어
                                    </div>
                                    <div :class="language == 'fr' ? 'lanItem lanItemHover':'lanItem'" @click="changeHandle('fr')">
                                        Français
                                    </div> -->
                                </div>

                            </div>
                        </div>
                        <div class="goverance-mobile" v-if="isMobile">
                                    <div class="goverance-box" v-if="isShowGoverance">
                                    <img src="@/assets/home/goverance.png"  class="goverance-img" />

                                    </div>
                                    <div class="goverance-box" v-if="isShowLightNode">
                                    <img src="@/assets/home/light-node.png"  class="goverance-img" />

                                    </div>
                        <div class="icon-block icon-block-uu" @click="toggleMobileNav" v-if="isMobile">
                            <div class="icon-menu"></div>
                        </div>
                        </div>
                    </div>
                    <div class="flex" v-if="!isMobile">
                        <div class="select-wallet"  v-if="!isMobile && isLogin">
                            <div class="select-wallet-left" @click="leftHandle" ref="infiRef">
                                <img src="@/assets/home/infi-icon.png" alt="" class="infi-icon">
                                {{ infiIntegralBalance |RetainDecimal }}
                                <img src="@/assets/home/down-arrow-white.png" alt="" class="down-arrow-white">
                                <div class="select-left-drow" v-if="isClaim" @click="claimHandle" >
                                    {{ $t("global.claimReward") }}
                                </div>
                            </div>
                            <div class="select-wallet-right" ref="infiLoginRef" @click="rightHandle">
                                {{ account|accountFn }}
                                <img src="@/assets/home/down-arrow-white.png" alt="" class="down-arrow-white">
                                <div class="select-right-out" v-if="isLoginOut">
                                    <div class="copy-address"
                                         v-clipboard:copy="account"
                                         v-clipboard:success="onAccountCopy"
                                         v-clipboard:error="onError"
                                    >{{ $t("global.copyAddress") }}</div>
                                    <div class="settings" @click="settingHandle">{{ $t("global.settingsText") }}</div>
                                    <div  @click="outHandle" class="login-out">{{ $t("global.logOut") }}</div>
                                </div>

                            </div>
                        </div>

                        <div class="wallet-connect" v-if="!isMobile && !isLogin" @click="connectHandle">{{ $t("global.connectWallet") }}</div>
                        <div class="invite-text" v-if="!isMobile && isLogin" @click="inviteHandle" >{{ $t("global.invite") }}</div>
                        <div class="goverance-box" v-if="isShowGoverance">
                          <img src="@/assets/home/goverance.png"  class="goverance-img" @mouseover="()=>goveranceHandle('isGoverance')" @mouseout="()=>goveranceHandle('isGoverance')"/>
                          <div class="pos-goverance-warp" v-if="isGoverance">
                            <img src="@/assets/home/goverance-icon.png"  class="goverance-img-icon"/>
                           {{$t('global.GoveranceNode')}}
                          </div>
                        </div>
                        <div class="goverance-box" v-if="isShowLightNode">
                          <img src="@/assets/home/light-node.png"  class="goverance-img" @mouseover="()=>goveranceHandle('isLightNode')" @mouseout="()=>goveranceHandle('isLightNode')"/>
                          <div class="pos-goverance-warp" v-if="isLightNode">
                            <img src="@/assets/home/light-node-icon.png"  class="goverance-img-icon"/>
                            {{$t('global.LightNode')}}
                          </div>
                        </div>
                    </div>
                </div>
                <transition name="fadeInRight"
                            v-if="isMobile"
                            enter-active-class="animate__animated animate__soon animate__fadeInRight "
                            leave-active-class="animate__animated animate__soon animate__fadeOutRight"
                >
                    <div class="drawer-wrapper"
                         v-if="openNav">
                        <div class="drawer-model"
                             @click="toggleMobileNav"></div>
                        <div class="drawer-container">
                            <header class="drawer-header">
                                <div class="logo-infinimos">
                                    <router-link :to="{name:'home'}" class="logo-text">
                                        Infinimos
                                    </router-link>
                                </div>

                                    <div class="icon-block" @click="toggleMobileNav">
                                        <div class="arrow-right"></div>
                                    </div>

                            </header>
                            <section class="drawer-body">
                                <div class="nav-cell">
                                    <router-link to="/exchange" class="home-link" id="home-link1" ref="myElement">
                                        {{ $t("menu.Exchange") }}
                                    </router-link>
                                    <router-link to="/ftoken" class="home-exchange" >
                                        {{ $t("menu.fTokenPool") }}
                                    </router-link>
                                    <router-link to="/infi" class="home-liquid" >
                                        {{ $t("menu.infiPool") }}
                                    </router-link>
                                    <router-link to="/airdrop" class="home-liquid text-gray" >
                                        {{ $t("menu.airDrop") }}
                                    </router-link>

                                    <a target="_blank" href="https://infinimos-organization.gitbook.io/infinimos-v-1.0-introduction/"  class="home-liquid" >
                                        {{ $t("menu.whitePaper") }}
                                    </a>
                                </div>
                                <div class="select-wallet-mobile-wrap">
                                    <div class="select-wallet-mobile" v-if="isLogin">{{ account|accountFn }}</div>
                                    <div class="wallet-connect" v-if="!isLogin" @click="connectHandle">{{ $t("global.connectWallet") }}</div>
                                    <div class="invite-mobile" @click="inviteHandle" v-if="isLogin">{{ $t("global.invite") }}</div>
                                </div>
                                <div class="infi-claim-mobile">
                                    <div class="infi-claim-mobile-left">
                                        <img src="@/assets/home/infi-icon.png" alt="" class="infi-icon">
                                        {{ infiIntegralBalance }}
                                    </div>
                                    <div class="infi-claim-mobile-right" @click="claimHandle">{{ $t("global.claimReward") }}</div>
                                </div>

                                <van-divider hairline class="line-mobile" />
                                <div class="foot-warp">
                                    <div class="copy-address-mobile"
                                         v-clipboard:copy="account"
                                         v-clipboard:success="onAccountCopy"
                                         v-clipboard:error="onError"
                                    >{{ $t("global.copyAddress") }}</div>
                                    <div class="footer-mobile" @click="outHandle">{{ $t("global.logOut") }}</div>
                                </div>

                            </section>

                        </div>
                    </div>
                </transition>
            </div>

        </div>
        <div class="notice" v-if="isInvest">
            <div class="desc">{{ arriveText }}</div>
            <button @click="investHandle">{{ $t('global.continueInvest') }}</button>
        </div>

        <router-view class="view"/>
        <div class="home-footer">
            <div class="version">VERSION V2.0.3</div>
            <div class="home-center">
               <p @click="()=>footHandle('term')">{{ $t("menu.term") }}</p>
               <p @click="()=>footHandle('privacy')">{{ $t("menu.privacy") }}</p>
               <p @click="()=>footHandle('disclaimer')">{{ $t("menu.disclaimer") }}</p>
            </div>
            <div class="footer-right">
                <div :class="language == 'zh' ? 'lanItem lanItemActiveHover lanItemHover':'lanItem'" @click="changeHandle('zh')">
                    中文
                </div>
                <div :class="language == 'en' ? 'lanItem lanItemActiveHover lanItemHover':'lanItem'" @click="changeHandle('en')">
                    English
                </div>
            </div>
        </div>

        <div class="registerModal registerModal-invite" v-if="dialogVisible" @click.stop="">
            <img src="@/assets/home/close-white.png" class="close-img" alt="" @click.stop="closeJoinDialog">
            <div class="title">{{ $t('global.inviteFriend') }}</div>
            <div class="subtitle">{{ $t('global.copy2Invite') }}</div>
            <!-- <textarea type="number" :placeholder="$t('global.referalPlaceHolder')" v-model="referralCode"
                      class="referralCode"/> -->
            <div class="invite-main">
                <div class="invite-link">{{ $t('global.inviteLink') }}</div>
                <div class="user-link">
                    <textarea :placeholder="$t('global.referalPlaceHolder')"  v-model="referralAddress"
                              class="referral-textarea" disabled/>
                    <!-- {{ referralAddress }} -->
                    <div  class="copy-link"
                        v-clipboard:copy="referralAddress"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                >
                <img src="@/assets/comp/copy-invite.png" alt="" class="copy-invite"/>
                {{ $t('global.copyLink') }}
                    </div>
                </div>
            </div>
            <!-- <div class="layerButtonContainer copy-link-box">
                <button class="layerButton copy-link"
                        v-clipboard:copy="referralAddress"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                >{{ $t('global.copyLink') }}</button>
            </div> -->
            <div class="position-warp">
                <div class="fToken-warp-title">
                    <div class="position-title"> {{ $t('global.accumulatedText') }}</div>

                </div>

                <div class="position-box">
                    <div class="position-item">
                        <div class="pending-item">
                            {{ $t("ftoken.fATOMPOOL") }}
                            <van-popover
                                v-model="showfATOMPoolPopover"
                                trigger="click"
                                class="info-img-box-pendding"
                                placement="top-end"
                                width="200"
                            >
                                <template #default>

                                    <div  class="pop-action">
                                        {{ actionsInfiPoolText }}
                                    </div>
                                </template>
                                <template #reference>
                                    <van-icon name="info-o"  class="info-pendding-img" size="20" color="#fff" @mouseover="showfATOMPoolPopover=true" @mouseout="showfATOMPoolPopover=false"/>
                                </template>
                            </van-popover>
                        </div>
                        <div class="position-amount">{{ inviteParams.fatomReward }} <span class="infi-text">INFI</span></div>
                    </div>
                    <div class="position-item">
                        <div class="pending-item">
                            {{ $t("ftoken.fTIAPOOL") }}
                            <van-popover
                                v-model="showfTiaPoolPopover"
                                trigger="click"
                                class="info-img-box-pendding"
                                placement="top-end"
                                width="200"
                            >
                                <template #default>

                                    <div  class="pop-action">
                                        {{ actionsInfiPoolText }}
                                    </div>
                                </template>
                                <template #reference>
                                    <van-icon name="info-o"  class="info-pendding-img" size="20" color="#fff" @mouseover="showfTiaPoolPopover=true" @mouseout="showfTiaPoolPopover=false"/>
                                </template>
                            </van-popover>
                        </div>
                        <div class="position-amount">{{ inviteParams.ftiaReward}}  <span class="infi-text">INFI</span></div>
                    </div>
                    <div class="position-item">

                        <div class="pending-item">
                            {{ $t("infi.InfiPool") }}
                            <van-popover
                                v-model="showInifPoolPopover"
                                trigger="click"
                                class="info-img-box-pendding"
                                placement="top-end"
                                width="200"
                            >
                                <template #default>

                                    <div  class="pop-action">
                                        {{ actionsInfiPoolText }}
                                    </div>
                                </template>
                                <template #reference>
                                    <van-icon name="info-o"  class="info-pendding-img" size="20" color="#fff" @mouseover="showInifPoolPopover=true" @mouseout="showInifPoolPopover=false"/>
                                </template>
                            </van-popover>
                        </div>
                        <div class="position-item-bottom">
                            <div class="position-amount">{{ inviteParams.infiReward }} <span class="infi-text">INFI</span></div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="your-Inviter" v-if="inviterAddress">{{ $t('invitePage.YourInviter') }}</div>
            <div class="invite-content" v-if="inviterAddress">
                <div>{{ formatAddress(inviterAddress,10) }}</div>
                <div>{{  inviterTime  }}</div>
            </div>
            <div class="your-invitee">
                <span class="your-invitee-box">
                    {{ $t('invitePage.YourInvitee') }}
                    <van-popover
                        v-model="showInviteePopover"
                        trigger="click"
                        class="info-img-box-pendding"
                        placement="top-start"
                        width="200"
                    >
                        <template #default>
                            <div  class="pop-action-first">
                                {{ actionsText }}
                            </div>
                        </template>
                        <template #reference>
                            <van-icon name="info-o"  class="info-pendding-img" size="20" color="#fff" @mouseover="mouseoverFirst" @mouseout="mouseoutFirst"/>
                        </template>
                    </van-popover>
                </span>
                <span class="total-text">{{ $t('invitePage.total') }}: {{ inviteeList.length }}</span>
            </div>
            <div class="extract-wrap">
                <vue-good-table
                    ref="myTable"
                    :key="tableKey"
                    :columns="columns"
                    :rows="inviteeList"
                    :sort-options="sortOptions"
                    :pagination-options="paginationOptions"
                    @on-sort-change="handleSort"

                >
                <template slot="table-row" slot-scope="props">
                    <span class="custom-col-content" v-if="props.column.field =='createTime'">
                        <span class="custom-amount">{{ utcToLocal(props.row.createTime,'YYYY-M-D')  }}</span>
                    </span>
                    <span class="custom-col-content" v-if="props.column.field =='userAddress'">
                        <span class="custom-amount custom-amount-copy">
                           <span class="custom-copy-address">{{ props.row.userAddress|accountFn  }}</span>
                            <img src="@/assets/comp/copy.png" alt="" class="copy-img"
                                        v-clipboard:copy="props.row.userAddress"
                                         v-clipboard:success="onAccountCopy"
                                         v-clipboard:error="onError" />
                        </span>
                    </span>

                    <span class="custom-col-content"  v-if="props.column.field =='fatomAmount'" >
                        <span >{{ props.row[props.column.field] || 0  }}</span>
                    </span>
                    <span class="custom-col-content"  v-if="props.column.field =='ftiaAmount'" >
                        <span >{{ props.row[props.column.field] || 0  }}</span>
                    </span>
                    <span class="custom-col-content"  v-if="props.column.field =='fethAmount'" >
                        <span >{{ props.row[props.column.field] || 0  }}</span>
                    </span>
                    <span class="custom-col-content"  v-if="props.column.field =='fsolAmount'" >
                        <span >{{ props.row[props.column.field] || 0  }}</span>
                    </span>
                    <span class="custom-col-content"  v-if="props.column.field =='infiAmount'" >
                        <span >{{ props.row[props.column.field] || 0  }}</span>
                    </span>
                </template>

                <template slot="emptystate" >
                    <span v-if="!showNoData && !totalDataNumber"></span>
                    <div class="custom-empty-message" v-if="!totalDataNumber && showNoData">
                        <img src="@/assets/comp/no-data.png" class="no-date-img" />
                        {{ $t("global.noRecords") }}
                    </div>
                </template>
            <!-- 自定义分页插槽 -->
                <template v-slot:pagination-bottom="slotProps" >
                    <v-pagination
                        :totalPage='totalDataNumber'
                        :current='pageParams.page'
                        :pagesize="paginationOptions.perPage"
                        @change-page='getHistory'
                        v-if="inviteeList.length>0"
                    />
                    <span v-else></span>
                </template>

            </vue-good-table>
            </div>

        </div>
        <!-- 邀请码 -->
        <div class="registerModal" v-if="isVisible" @click.stop="">
            <img src="@/assets/home/close-white.png" class="close-img" alt="" @click.stop="closeJoinDialog">
            <div class="title subtitle-register">{{ $t("global.ReferralCode") }}</div>

            <div class="invite-main">
                <div class="user-link">
                    <textarea :placeholder="$t('global.referalPlaceHolder')"  v-model="registerReferrallAddress"
                              class="referral-textarea"/>

                </div>
            </div>
            <div class="layerButtonContainer">
                <button class="layerButton"
                        @click="bindReferral"
                >{{ $t('global.submit') }}</button>
            </div>
        </div>
        <div class="mask" v-show="maskVisible"></div>
        <div class="registerModal registerModal-sign" v-if="isSign">
            <div class="title">{{ $t('global.signTitle') }}</div>
            <div class="content-sign">{{ $t('global.signContent') }}</div>
            <div class="layerButtonContainer">
                <button class="layerButton" @click="signatureHandle">{{ $t('global.signBtn') }}</button>
            </div>
       </div>
       <div class="claim-dialog" v-if="isClaimDialog">
            <div class="header-close" @click="closeHandle">
                <img src="@/assets/home/close-white.png" class="close-img" >
            </div>
            <div class="claim-dialog-title">{{ $t('global.claimYourRewards') }}</div>
            <div class="claim-dialog-main">
                <div class="claim-infi-text">INFI</div>
                <input placeholder="0"  type="number" class="amount-input get-amount-input" value="">
            </div>
            <div class="collect-dialog-now" @click="collectNowHandle">{{ $t('global.collectNow') }}</div>
        </div>
       <div class="footer-bottom" v-if="isFooter">
        <div class="header-close" @click="closeHandle">
            <img src="@/assets/home/close-white.png" class="close-img" >
        </div>
        <div class="claim-footer-title">{{ termTitle }}</div>
        <Footer v-if="isFooterType=='term'"/>
        <Footer1 v-if="isFooterType=='privacy'"/>
        <Footer2 v-if="isFooterType=='disclaimer'"/>
       </div>
    </div>
</template>
<script>
import {mapGetters, mapState, mapActions} from "vuex";
import {
    formatAddress,
    getAccount,
    getBalance,
    claim, isValidJunoAddress, isValidCelestiaAddress
} from "@/utils/cosmMain";
import {
    getSysParams,
    getUser,
    register,
    join,
    registerTia,
    getReferral,
    getAxiosTeamReward
} from "@/api";
import {goCommonPath, utcToLocal} from "@/utils/common";
import CryptoJS from 'crypto-js'
import NoticeBar from '@/components/NoticeBar'
import {keplrConfig} from "@/config/keplr";
import {ATOM, fATOM, wATOM, getConfig, INFI, fTIA} from "@/config/network";
import Footer from '@/components/footerDetail/index'
import Footer1 from '@/components/footerDetail/index1'
import Footer2 from '@/components/footerDetail/index2'

import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import bitgetWalletModule from '@web3-onboard/bitget'

import { ethers } from 'ethers'

const wcInitOptions = {
    /**
     * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
     */
    projectId: process.env.VUE_APP_WC_PROJECT_ID,
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    requiredChains: [process.env.VUE_APP_CHAINID_ETH_DEC],
    // requiredChains: [1,11155111],
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    optionalChains:  [42161, 8453, 10, 137, 56],
    /**
     * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
     * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
     * To connect with WalletConnect
     */
    dappUrl: 'https://stake.infinimos.net'
}
const WalletConnect = walletConnectModule(wcInitOptions)
// const MAINNET_RPC_URL = 'https://rpc.mevblocker.io'

const injected = injectedModule()
// const safe = safeModule()
const bitgetWallet = bitgetWalletModule()


const MAINNET_RPC_URL = `https://mainnet.infura.io/v3/3c460c0fb2a64d618143df3bcd83ed78`;
const appMetadata = {
    name: 'Infinimos ETH connection',
    logo:require('@/assets/home/goverance-icon.png'),
    icon: require('@/assets/home/goverance-icon.png'), // svg string icon

    description: 'ETH connected successful',
    recommendedInjectedWallets: [
        { name: 'MetaMask', url: 'https://metamask.io' },
    ],
}
const onboard = Onboard({

    wallets: [injected],//,bitgetWallet,WalletConnect,
    chains: [
        // {
        //   id: '0x1',
        //   token: 'ETH',
        //   label: 'Ethereum Mainnet',
        //   rpcUrl: MAINNET_RPC_URL
        // },
        {
            id: process.env.VUE_APP_CHAINID_ETH_HEX,
            token: process.env.VUE_APP_CHAINID_ETH_TOKEN,
            label: process.env.VUE_APP_CHAINID_ETH_LABEL,
            rpcUrl: process.env.VUE_APP_RPC_ETH
        },

    ],
    connect: {
        autoConnectLastWallet: true,
        // showSidebar:false,
        removeIDontHaveAWalletInfoLink:true,
        removeWhereIsMyWalletWarning:true,
    },
    accountCenter:{
    },

    theme:'dark',
    appMetadata
})


// 初始化 Onboard
// onboard.walletSelect();

export default {
    components: {NoticeBar,Footer,Footer1,Footer2},
    watch: {
        async $route(route) {
            const {path, name} = route;
            this.$nextTick(() => {
                const dom = document.querySelector('#home-link')
                if (dom) {
                    dom.className = name == 'exchange' ? "home-link router-link-active" : "home-link text-gray"
                }
                this.toggleNav(false)
            })
        },
    },
    data() {
        const that = this;
        return {
            isVisible:false,
            registerReferrallAddress:'',
            referralAddress:'',
            langMenuShow: false,
            isMobile: false,
            dialogVisible:false,
            referralCode:null,
            isInvest: false,
            arriveText:this.$t('global.arriveOver'),
            notice:this.$t('global.noticeText'),
            isClaim:false,
            isClaimDialog:false,
            isLogin:false,
            isLoginOut:false,
            inviterAddress:null,
            inviterTime:null,
            infiIntegralBalance:0, // infi积分余额
            isFooter:false,
            isFooterType:'',
            termTitle:'',
            sortOptions: { // 表格
             enabled: true, // 禁用排序
             initialSortBy: {}
            },
            tableKey: 0,
            columns:[
                {
                label: this.$t('invitePage.address'),
                'no-sort': false,
                field: 'userAddress',
                width:'120px'
                },
                {
                label: this.$t('invitePage.fAtomStaking'),
                'no-sort': false,
                field: 'fatomAmount',
                width:'100px'
                },
                {
                label: this.$t('invitePage.fTIAStaking'),
                'no-sort': false,
                field: 'ftiaAmount',
                width:'100px'
                },
                {
                label: this.$t('invitePage.fETHStaking'),
                'no-sort': false,
                field: 'fethAmount',
                width:'100px'
                },
                {
                label: this.$t('invitePage.fSOLStaking'),
                'no-sort': false,
                field: 'fsolAmount',
                width:'100px'
                },
                {
                label: this.$t('invitePage.INFIStaking'),
                'no-sort': false,
                field: 'infiAmount',
                width:'100px'
                },
                {
                label:  this.$t('invitePage.date'),
                field: 'createTime',
                'no-sort': false,
                width:'100px'
                },

            ],
            sortType:{
                userAddress:{asc:1,desc:2},
                createTime:{asc:3,desc:4},
            },
            showNoData:true,
            totalDataNumber:0,
            sideTotalDataNumber:2,
            inviteeList:[],
            dataList:[],
            currentPage:1,
            paginationOptions: {
                enabled: true, // 启用分页
                perPage: 10,
                currentPage:1,
                setCurrentPage:1 //当前表格页码 // 每页显示的行数
            },
            pageParams:{
                "page": 1,
                "pageSize": 10,
                "sortType":1
            },
            inviteParams:{
                fatomReward:0,
                ftiaReward:0,
                infiReward:0,
            },
            showInviteePopover:false,
            actionsText:this.$t('global.invitePopText'),
            showInifPoolPopover:false,
            showfTiaPoolPopover:false,
            showfATOMPoolPopover:false,
            actionsInfiPoolText:this.$t('global.inviteActionText'),
            isGoverance:false,
            isLightNode:false,
            isShowGoverance:false,
            isShowLightNode:false
        }
    },
    directives: {  // 使用局部注册指令的方式
        resize: { // 指令的名称
            bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
                let width = '', height = '';
                function isReize() {
                    const style = document.defaultView.getComputedStyle(el);
                    if (width !== style.width || height !== style.height) {
                        binding.value();  // 关键
                    }
                    width = style.width;
                    height = style.height;
                }
                el.__vueSetInterval__ = setInterval(isReize, 300);
            },
            unbind(el) {
                clearInterval(el.__vueSetInterval__);
            }
        }
    },
    computed: {
        ...mapGetters(['openNav', 'account', 'userInfo', 'language', 'maskVisible', 'sysParam','accountInformation','isSign']),
    },
    filters:{
        accountFn(address){
            return address?address.slice(0,6)+'...'+address.slice(-5):''
        }
    },
    async mounted() {
        this.setOnboard(onboard)
        console.log(localStorage.getItem('isLogin'),'isLogin')
        this.isLogin =  localStorage.getItem('isLogin')
        const {name} = this.$route
        if (name == 'home' ) {
            const dom = document.querySelector('#home-link')
            console.log(dom,'dom===>')
            if (dom) {
                dom.className =  name == 'home' ? "home-link router-link-active" : "home-link text-gray"
            }
        }

        const isInitMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
        if (isInitMobile) {
            this.isMobile = true
        }
        let that = this

        if (this.sysParam == null) {
            let {data} = await getSysParams()
            await this.setSysParam(data)
        }

        if (window.keplr) {
            try{
                await this.web3Init()

            }catch(err){
                console.log(err,'err===>')
            }

            await this.signIn()
        } else {
        }

        setTimeout(function () {
            if (!window.keplr) {
                that.setMaskVisible(true)

                that.$msg.error(that.$t("global.useDappExplorer"), -1, true)
            }
        }, 5000); // 3 seconds



        document.addEventListener("click", e => {
            if (this.$refs.infiRef) {
                let self = this.$refs.infiRef.contains(e.target);
                if (!self) {
                    this.isClaim = false;
                }
            }
            if(this.$refs.infiLoginRef){
                let self = this.$refs.infiLoginRef.contains(e.target);
                if (!self) {
                    this.isLoginOut = false;
                }
            }
        })


    },
    methods: {
        utcToLocal,
        goCommonPath,
        formatAddress,
        ...mapActions('app', ['toggleNav', 'setLanguage', 'setAccount', 'setUserInfo', 'setMaskVisible', 'setSysParam','setAccountInformation','refreshToken','setSign','setOnboard']),
        async initkeplr(){


            const KeplrMobile = new wallets.KeplrMobileWallet();
            // await  KeplrMobile.enable();
            KeplrMobile.enable().then(() => {
                console.log('89898')
            // 钱包已成功连接
            // 在此执行你的逻辑操作，例如签名交易或获取账户信息等
            }).catch((error) => {
            // 处理错误
            console.error('Failed to enable Keplr mobile wallet:', error);
            });
        },
        settingHandle(){
            this.$router.push({name:'settings'})
        },
        footHandle(type){
          console.log(type,'type==>')

          const termParam = {
                term:this.$t("menu.term"),
                privacy:this.$t("menu.privacy"),
                disclaimer:this.$t("menu.disclaimer"),
            }
          this.isFooterType = type
          this.termTitle = termParam[type]
          this.setMaskVisible(true)
          this.isFooter = true
        },
        goveranceHandle(type){
            this[type] = !this[type]
        },
        mouseoverFirst(){
            this.showInviteePopover  = true
        },
        mouseoutFirst(){
            this.showInviteePopover  = false
        },
        async getUserTeamReward(){
            const { data } = await getAxiosTeamReward()
            console.log(data,'getUserTeamReward==>getUserTeamReward')
            this.inviteParams = data
        },
        async getAxiosUserIntegral(){
            const {data} = await getUser()
            console.log(data,'data')
            console.log(data.stakePoint)
            if(!data) {
                this.infiIntegralBalance = data.stakePoint;
                return
            };

        },
        resize(){
            console.log('resize==>')
            const reg = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            const isInitMobile = reg.test(navigator.userAgent);
            if (isInitMobile) {
                this.isMobile = true
            }else{
                this.isMobile = false;

            }

        },
        leftHandle(){
            if(this.infiIntegralBalance<=0){
                this.$msg.warning(this.$t("global.noClaimableInfi"), 3000,true)
                return
            }
            this.isClaim = !this.isClaim
        },
        claimHandle(){
            // this.$msg.warning('coming soon!', 3000,true)
            // this.setMaskVisible(true)
            // this.isClaimDialog = true;
            this.$router.push({
                name: 'airdrop'
            })
        },
        rightHandle(){
            this.isLoginOut = !this.isLoginOut
        },
        submitHandle(){

        },
        // 链接钱包
        async connectHandle(){
              if (!window.keplr) {
                this.setMaskVisible(true)
                this.$msg.error(this.$t("global.useDappExplorer"), -1, true)
                return false;
            }
            await this.web3Init()
            await this.signIn()
            this.isLoginOut = false
        },
        //  退出登录
        async outHandle(){
            const account = await getAccount()
            localStorage.removeItem('account')
            localStorage.removeItem('isLogin')
            localStorage.removeItem('storeAccountToken')
            // const storeAccountToken = localStorage.getItem('storeAccountToken')
            // delete storeAccountToken[account]
            // localStorage.setItem('storeAccountToken',storeAccountToken)
            this.isLogin = false
        },
        async collectNowHandle(){
            this.isClaimDialog = false
            this.setMaskVisible(false)
            await claim(this,this.infiIntegralBalance)
        },
        inviteHandle(){
            this.getUserTeamReward()
            this.referralAddress = 'https://infinimos.net/?referral='+this.account//this.shareLink+this.account
            console.log(this.referralAddress)
            this.setMaskVisible(true)
            this.dialogVisible = true;
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // 使用平滑滚动效果
            });
        },
        closeHandle(){
            this.setMaskVisible(false)
            this.isClaimDialog = false;
            this.isFooter = false
        },
        signatureHandle(){
            // this.setMaskVisible(false)
            this.setSign(false)
            this.refreshToken()
        },
        investHandle(){
            this.$router.push("lpMining")
        },
        async getInfiBalance() {
            try{
                let account = await getAccount()
                let balance = await getBalance(account,INFI)
                // this.infiIntegralBalance = (Number(balance.balance) / 1e6)
            }catch(err){
                console.log(err)
            }

        },
        async web3Init() {

            window.keplr.experimentalSuggestChain(keplrConfig(getConfig.juno))

            try {
                await window.keplr.suggestToken(
                    getConfig.juno.chainId,
                    fATOM,
                )
                // window.keplr.suggestToken(
                //     getConfig.juno.chainId,
                //     wATOM,
                // )
                // window.keplr.suggestToken(
                //     getConfig.juno.chainId,
                //     INFI,
                // )
                // window.keplr.suggestToken(
                //     getConfig.juno.chainId,
                //     fTIA,
                // )

                window.keplr.enable([getConfig.juno.chainId,getConfig.celestia.chainId])
            } catch (e) {
                console.log(e,'err')
                this.$msg.warning(this.$t("web3.ACTION_REJECTED"))
            }

            localStorage.setItem('isStoreToken',1)
            let that = this
            let account = await getAccount()
            this.setAccount(account)
            // this.isLogin = true;
            window.addEventListener("keplr_keystorechange", async () => {
                let account = await getAccount()
                that.setAccount(account)
                that.$msg.success(that.$t("global.changeAccount") + formatAddress(account))
                console.log('reload===>124334545445')
                setTimeout(function () {
                    window.location.reload()

                }, 2000)
            })
            window.addEventListener("keplr_chainchanged", async (event) => {
                console.log('reload===>处理区块链网络切换事件')
                    // 处理区块链网络切换事件
                    let account = await getAccount()
                that.setAccount(account)
                that.$msg.success(that.$t("global.changeAccount") + formatAddress(account))
                console.log('reload===>处理区块链网络切换事件')
                setTimeout(function () {
                    window.location.reload()

                }, 2000)
            });


        },
        changeHandle(lan) {
            console.log(lan,';language')
            this.$i18n.locale = lan
            this.setLanguage(lan)
            this.langMenuShow = false
            window.location.reload()
        },
        joinInfinimos(){
            this.dialogVisible=true
            this.setMaskVisible(true)
        },

        closeJoinDialog(){
            this.dialogVisible=false
            this.setMaskVisible(false)
            this.isVisible = false
        },
        encrypt(str, secret){
            const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
            const cryptoOption = {
                iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            };
            const encryptedStr = CryptoJS.AES.encrypt(str, cryptoKey, cryptoOption).toString();

            return encryptedStr;
        },
        async signIn() {
            let storeAccountToken =  JSON.parse(localStorage.getItem('storeAccountToken'))
            if(!storeAccountToken){
                this.setMaskVisible(true)
                this.setSign(true)
            }

            this.getHistory()

            let account = await getAccount()

            //根据地址查询用户信息，若用户未注册，发起注册操作
            let result = await getUser(account)

            console.log(result,'result==>')

            if(result.code==200){
                let data = result.data
                this.setUserInfo(data)
                console.log(data,'data===>')
                //获取入参，判断是否有邀请链接，若有,注册时带入服务端
                let referral = this.$route.query.referral
                this.referralCode=referral || ""
                if (data == null ) {


                    await this.register()
                }else{
                    //用户已注册且未绑定邀请人，且目前已获得格式正确的邀请人地址，绑定
                    if ((data.inviterAddress==null || data.inviterAddress=="") && referral && isValidJunoAddress(referral)) {
                        await this.join();
                    }
                    //用户已注册且未绑定tia地址，则获取到正确格式的TIA地址后注册TIA
                    if (data.tiaAddress==null || data.tiaAddress=="") {
                        let tiaAccount = await getAccount(getConfig.celestia)
                        if(tiaAccount && isValidCelestiaAddress(tiaAccount)){
                            await this.registerTia(data.userAddress,tiaAccount);
                        }
                    }



                    this.infiIntegralBalance = data.stakePoint;
                }
                this.inviterAddress=this.userInfo.inviterAddress
                this.inviterTime= utcToLocal(this.userInfo.updateTime,'MMMM D, YYYY')
                if(data.nodeFlag==1){
                    this.isShowGoverance = true
                }else if(data.nodeFlag==2){
                    this.isShowLightNode = true
                }

            }
        },
        onAccountCopy:function(e){
            this.$msg.success(this.$t("global.copySucc"))
        },
        //复制成功的回调
        onCopy:function(e){
            this.$msg.success(this.$t("global.BindSucc"))
        },
        //复制失败的回调
        onError:function(e){
            this.$msg.error(this.$t("global.copyFailed"))
        },
        async bindReferral() {

            if(await this.register()){
                this.closeJoinDialog()
                this.$msg.success(this.$t("global.BindSucc"))
                setTimeout(function () {
                    window.location.reload()

                }, 2000)
            }

        },

        async join() {
            //若当前已有绑定关系，不允许再绑
            if(this.userInfo!=null && this.userInfo.inviterAddress!=null&& this.userInfo.inviterAddress!=""){
                this.$msg.error(this.$t("global.BindDuplicate"))
                return false
            }
            //获取输入的推荐码
            //推荐码非空判断
            if(this.referralCode==null || this.referralCode ==""){
                this.$msg.error(this.$t("global.referralCodeEmpty"))
                return false
            }
            //推荐码格式判断
            if(!isValidJunoAddress(this.referralCode)){
                this.$msg.error(this.$t("global.referralCodeFormat"))
                return false
            }
            //推荐码不能是自己
            let account = await getAccount()
            if(this.referralCode.toLocaleLowerCase() == account.toLocaleLowerCase()){
                this.$msg.error(this.$t("global.referSelf"))
                return false
            }

            let param = {
                userAddress: account,
                inviterAddress: this.referralCode
            }
            let result = await join(param)
            if (result.data != null) {
                this.setUserInfo(result.data)
            }
            return  true
        },
        async registerTia(userAddress,tiaAddress) {
            //若当前已有绑定关系，不允许再绑
            if(this.userInfo!=null && this.userInfo.tiaAddress!=null&& this.userInfo.tiaAddress!=""){
                return false
            }
            //推荐码非空判断
            if(tiaAddress==null || tiaAddress ==""){
                return false
            }
            //推荐码格式判断
            if(!isValidCelestiaAddress(tiaAddress)){
                return false
            }
            let param = {
                userAddress: userAddress,
                tiaAddress: tiaAddress
            }
            let result = await registerTia(param)
            if (result.data != null) {
                this.setUserInfo(result.data)
            }
            return  true
        },

        async register() {
            let account = await getAccount()

            //若当前已有绑定关系，不允许再绑
            if(this.userInfo!=null && this.userInfo.inviterAddress!=null&& this.userInfo.inviterAddress!=""){
                this.$msg.error(this.$t("global.BindDuplicate"))
                return false
            }
            //获取输入的推荐码
            //推荐码非空判断
            // if(this.referralCode==null || this.referralCode ==""){
            //     this.$msg.error(this.$t("global.referralCodeEmpty"))
            //     return false
            // }
            //推荐码格式判断
            if(this.referralCode!=null && this.referralCode !=""){
                if(!isValidJunoAddress(this.referralCode)){
                    this.$msg.error(this.$t("global.referralCodeFormat"))
                    return false
                }
                //推荐码不能是自己
                if(this.referralCode.toLocaleLowerCase() == account.toLocaleLowerCase()){
                    this.$msg.error(this.$t("global.referSelf"))
                    return false
                }
            }
            let tiaAccount = await getAccount(getConfig.celestia)

            let param = {
                userAddress: account,
                inviterAddress: this.referralCode,
                tiaAddress:tiaAccount
            }
            let result = await register(param)
            if (result.data != null) {
                this.setUserInfo(result.data)
            }
            return  true
        },
        toggleLangNav() {
            this.langMenuShow = !this.langMenuShow
        },
        toggleMobileNav() {
            const {name} = this.$route
            this.toggleNav(!this.openNav)
            this.$nextTick(() => {
                const dom = document.querySelector('#home-link1')
                dom.className = name == 'exchange' ? "home-link router-link-active" : "home-link text-gray"
            })
        },
        handleSort(event) {
        // Retrieve the sort information from the event
        const { field, type } = event[0];
         this.pageParams.sortType = this.sortType[field][type]
         this.getHistory(this.pageParams.page)
        },
        async getHistory(current,options){
            let account = await getAccount()
            let params = {...this.pageParams,...{address:account}}
            console.log(current,'current==>')
            if(current){
                this.pageParams.page = current
                params = {...this.pageParams,...{page:current}}
                this.paginationOptions = {...this.paginationOptions,...{setCurrentPage:current},...{currentPage:current}}
            }
            if(options){
                if(options.perPage){
                    params = {...params, ...{pageSize:options.perPage}}
                    this.pageParams.pageSize = options.perPage
                }
                this.paginationOptions = {...this.paginationOptions,...options}
                this.tableKey++
            }
            // 调用接口
            let result1 = await getReferral(params)

            if(result1 != null){
                this.inviteeList= result1.data
                this.totalDataNumber = result1.count||0
            }
        },
    }
}
</script>
<style lang="less">
.goverance-img{
    width:57px;
    height: 41px;
    margin-left:16px;
    cursor: pointer;
}
.goverance-mobile{
    display: flex;
    align-items: center;
    .goverance-box{
        margin-right:10px;
    }
    .goverance-img{
        width: 44px;
        height: 32px;;
    }

}
.goverance-box{
    position: relative;
}
.pos-goverance-warp{
    position: absolute;
    top:50px;
    right:0px;
    white-space: nowrap;
    border-radius: 8px;
    background: #0F0F16;
    display: flex;
    justify-content: center;
    padding: 16px;
   width:calc(208px - 32px);
    height: calc(59px - 32px);
    color: #D7D7D7;
    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.goverance-img-icon{
   margin-right:8px;
   width:33px;
   height:27px;
}
.footer-bottom{
    position: absolute;
    top: 15vh;
    left: 50%;
    display: flex;
     padding: 0 16px;
    flex-direction: column;
    /* align-items: center; */
    gap: 0.83333rem;
    background-color: #000000;
    border-radius: 0.3125rem;
    width: 49.32292rem;
    transform: translateX(-50%);
    z-index: 999;
}
.custom-copy-address{
    width: 100px;
}
.custom-amount-copy{
    display: flex;
    align-items: center;
}
.copy-img{
    width:24px;
    height: 24px;;
    cursor: pointer;
    margin-left:5px;
}
.claim-footer-title{
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.456px;
}
.version{
    color: #4E4E4E;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.72px;
}
.home-footer{
    display: flex;
    align-items: center;
    padding: 32px 120px;
    border-top:1px solid #454545;
    justify-content: space-between;
}
.home-center{
    display: flex;
    align-items: center;
    p{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.72px;
        margin-right:32px;
        cursor: pointer;
    }
}
.footer-right{
    display: flex;
    align-items: center;
    .lanItem{
        color: #616161;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.72px;
        cursor: pointer;
    }
    .lanItemActiveHover{
        color:#fff
    }
    .lanItem:first-child{
        margin-right:16px;
        padding-right:16px;
        border-right: 1px solid #3C3C3C;
    }
}
.select-wallet-left{
    display: flex;
    height: 41px;
    padding: 0px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px 0px 0px 6px;
    border: 0px solid #00A3FF;
    background: #00A3FF;
    color: #FFFFFF;
    cursor: pointer;
    position: relative;
}
.white-paper-mobile{
    display: flex;
    width: calc(100% - 56px);
    height: 48px;
    padding: 8px 12px;
    margin:12px auto 0;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: #00A3FF;
    color:#fff;
}
.white-paper-mobile a{
    color:#fff;
    text-align: center;
    width:100%;

}
.down-arrow-white{
    width:16px;
    height: 16px;
}
.infi-icon{
    min-width:16px;
    height: 16px;
}
.select-wallet{
    display: flex;
}
.select-wallet-right{
    display: flex;
    height: 38.5px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid #00A3FF;
    color: #00A3FF;;
    cursor: pointer;
    margin-right:16px;
    position: relative;
}
.select-right-out{
    position: absolute;
    top: 50px;
    right: 0px;
    color:#00A3FF;
    cursor: pointer;
    border-radius: 8px;
    background: #0F0F16;
    padding: 16px;
}
.copy-address{
    white-space: nowrap;
    color: #00A3FF;

    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;

}
.settings{
    padding-top:16px;
    padding-bottom:16px;
    white-space: nowrap;
    color: #00A3FF;

    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.login-out{
    color:  #CC0905;

    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.referral-textarea{
    width: 100%;
    background: transparent;
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent;
    align-items: center;
    word-break: break-all;
    border: 0;
    resize: none;
    appearance: none;
    text-align: center;
    height: 20px;
    line-height: 20px;
}
.referral-textarea::-webkit-scrollbar{
    width: 0;
    height: 0;
}
.select-left-drow{
    position: absolute;
    top: 46px;
    left: 0px;
    padding: 16px;
    /* width: 180px; */
    white-space: nowrap;
    color:#00A3FF;
    cursor: pointer;
    // padding:6px 0;
    border-radius: 8px;
    background: #0F0F16;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
    // border: 1px solid #00A3FF;
}
.claim-dialog{
    position: absolute;
    left:50%;
    top:50%;
    width:calc(900px - 24px);
    transform: translate(-50%,-50%);
    border-radius: 24px;
    padding: 24px;
    z-index:998;
    background: #0F0F16;
}
.claim-dialog-title{
    color:  #FFF;
    font-family: Inter;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-top:48px;
    line-height: 56.816px; /* 129.128% */
    text-transform: uppercase;
}
.claim-dialog-main{
    display: flex;
    width: calc(100% - 48px);
    height: 104px;
    margin:48px auto;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding:0 24px;
    border-radius: 12px;
    background: #353548;
}
.claim-infi-text{
    color:  #FFF;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.2px;
}
.amount-input {
    // border: 1px solid #ced4da;
    outline: none;
    border:0;
    background:none;
    text-align: right;
    width: 100%;
    caret-color:#00A3FF;
    color:#fff;
}
.amount-input:focus{
    caret-color:#00A3FF;
    color:#00A3FF;
}
.amount-input::-webkit-input-placeholder {
    color:  #00A3FF;
    text-align: right;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.44px;
}
.collect-dialog-now{
    display: flex;
    width: 303px;
    height: 48px;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin:0 auto 48px;
    border-radius: 6px;
    background: #00A3FF;
    color:  #F6F8F9;
    text-align: center;
    /* Headings/Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
    cursor: pointer;
}
.header-close{
    position: absolute;
    right:20px;
    top:20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

nav a.contact-link-node {
    width: 137px;
    padding: 10px 10px;
    color: #000;
    font-weight: 600;
    background: linear-gradient(96.85deg, #51FFA8 1.03%, #00D147 101.26%);
    box-shadow: 0px 0px 11px #51FFA8;
    border-radius: 8px;
}
.hide{
    display: none;
}
.header-container-nav .logo-text{
    font-size: 24px;
    font-weight: bold;
    border-bottom:0!important;
    color:#fff!important;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 37.975px; /* 118.671% */
}

.flex-content{
    flex:1;
    display: flex;
    justify-content: flex-end;
    margin-left: 100px;
}

.nav-cell-wrap a{
    margin-left:20px;
    color: #FFF;
    text-align: center;
    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.nav-cell-wrap a:hover{
    color:#00A3FF
}
.text-gray{
    position: relative;
}
.text-gray:hover::after{
    width: 100%;
    left: 0;
    right: unset;
}
.text-gray::after{
    position: absolute;
    right: 0;
    bottom: -2px;
    content: "";
    display: block;
    height: 1px;
    width: 0;
    background-color: #00A3FF;
    transition: width .35s cubic-bezier(.22,.84,.48,.99);
}
.nav-cell-wrap-pc{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-line{
    width: 1px;
    height: 34px;
    background: #292929;
    margin-left:20px;
    margin-right:20px;
    display: inline-block;
}
.nav-cell-wrap-pc .router-link-active{
    border-bottom:1px solid #00A3FF;
}
.bate-text {
    transform: scale(0.8);
    display: inline-block;
    margin-left: -10px;
}
.wallet-connect{
    color:#fff;
    // border:1px solid #00A3FF;
    border-radius: 6px;
    padding:5px 16px;
    cursor: pointer;
    margin-right:16px;
    background:#00A3FF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */

}
.invite-text{
    display: flex;
    height: 41px;
    padding: 0px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #00A3FF;
    text-align: center;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: rgba(0, 163, 255, 0.30);
    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    // line-height: 24px; /* 171.429% */
    // letter-spacing: 0.084px;
    text-transform: uppercase;
    cursor: pointer;
}
.select-wallet-mobile-wrap{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:12px;
    width: calc(100% - 32px);
    margin:0 auto 12px;

}
.select-wallet-mobile{
    display: flex;
    height: 41px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 1px solid #00A3FF;
    color: #00A3FF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.invite-mobile{
    display: flex;
    // height: 48px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: rgba(0, 163, 255, 0.30);
    color:#00A3FF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.infi-claim-mobile{
    display: flex;
    width: calc(100% - 56px);
    height: 48px;
    padding: 8px 12px;
    margin:0 auto;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: #00A3FF;
    color:#fff;
}
.infi-claim-mobile-left{
    display: flex;
    align-items: center;
    color: #FFF;
    /* Headings/Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
    img{
        margin-right:5px;
        object-fit: contain;
        width: 20px;
        height: 20px;
    }
}
.infi-claim-mobile-right{
    color: #FFF;
    /* Headings/Heading 14 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    text-transform: uppercase;
}
.drawer-body .line-mobile{
    width: calc(100% - 32px);
    margin:16px auto;
}
.foot-warp{
    display: flex;
    width: calc(100% - 32px);
    margin:0 auto;
}
.copy-address-mobile{
    padding: 8px 12px;
    margin:0 auto;
    display: flex;
    flex:1;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 0px solid #00A3FF;
    background: #00A3FF;
    color:#fff;
    margin-right:16px;
}
.footer-mobile{
    display: flex;
    // width: calc(100% - 56px);
    flex:1;
    height: 48px;
    line-height: 48px;;

    padding: 8px 12px;
    margin:0 auto;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    background: #F2271C;
    color:#fff;
    text-align: center;

    /* Headings/Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;

}
.flex{
    display: flex;
}
* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
}

#app {
    font-family: 'Noto Sans', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;

    position: relative;
    // background: rgb(11, 11, 15);
    background: #000;
    height: auto;
    // color: #fff;

    .notice{
        min-height: 37px;
        background: #FFEE58;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 22;
        position: relative;
        .desc{
            min-height: 21px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #2B2E3D;
            line-height: 21px;
            margin-left: 13px;
        }
        button{
            width: 79px;
            min-height: 28px;
            background: #FF7D58;
            border: 0;
            border-radius: 3px;
            font-size: 13px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #fff;
            line-height: 20px;
            margin-right: 8px;
        }
    }
    .seamless-warp{
        height: 37px;
        background: #d6ffe9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        //  border-top:1px solid #363636;
        margin: 0 auto;

        .item{
            // width: 200px;
            list-style: none;
            padding: 0;
            margin: 0 auto;
            display: flex;
        }
    }
}

img {
    max-width: 100%;
    vertical-align: middle;
}

ul {
    list-style: none;
}

nav {
    position: sticky;
    padding: 0 120px 0 150px;
    display: flex;
    justify-content: space-between;
    height: 95px;
    top: 0;
    align-items: center;
    z-index: 1000;

    .nav_div {
        width: 700px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .logo {
        height: 40px;
    }

    a {
        display: inline-block;
        color: #fff;
        position: relative;
        text-decoration: none;
        font-size: 14px;
        margin: 0 10px;
        white-space: nowrap;


        //活跃时的样式
        &.router-link-exact-active {
            color: #00A3FF;
            transition: all linear 150ms;

            &::after {
                content: ' ';
                position: absolute;
                left: 0;
                bottom: -4px;
                width: 100%;
                height: 2px;
                background-color: #00A3FF;
            }
        }

        &.contact-link {
            color: #fff;
            background: #51FFA8;
            color: #000;
            border-radius: 10px;
            padding: 10px 10px;
            font-weight: bolder;
            margin: 0;

            &::after {
                content: unset;
            }
        }
    }
}

.mobile-nav {
    // display: none;
    position: relative;
    z-index: 996;

    .header-container-nav {
        padding: 24px 120px;

        display: flex;
        align-items: center;
        justify-content: space-between;


        .logo {
            width: 60.754px;
            // height: 53px;
        }

        .right {
            display: flex;
            // gap: 18px;
            // width: 675px;
            .language {
                display: flex;
                align-items: center;
                gap: 4px;
                position: relative;
                cursor: pointer;
                button {

                    font-family: BaiJamjuree-Medium, BaiJamjuree;
                    font-weight: 500;
                    color: #fff;
                    line-height: 28px;
                    background: transparent;
                    border: 0;

                }
            }

        }


        .down {
            width: 30px;
            height:22px;
        }

        .icon-block {
            .iconfont {
                font-size: 18px;
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }

    .languageWraper {
        position: absolute;
        bottom: 0;
        right: 0;
        // height: 100vh;
        width: 210px;
        z-index: 99;
        display: flex;
        justify-content: flex-end;

        .drawer-model {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .langList {
            width: 180px;
            // height: 230px;
            position: absolute;
            right: 0;
            top: 10px;
            padding:5px 16px;
            background: rgba(26, 29, 40, 0.7);
            box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(4px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .lanItem {
                font-size: 20px;
                font-family: BaiJamjuree-Regular, BaiJamjuree;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;

            }

            .lanItemHover {
                color: #1DD79B;
            }
        }
    }


}


.icon-menu {
    width: 32px;
    height: 32px;
    background: url('@/assets/icon-menu-white.png') no-repeat;
    background-size: 100% 100%;
}

.drawer-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
    display: flex;
    justify-content: flex-end;

    .drawer-model {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }

    .drawer-container {
        max-width: 210px;
        height: 100%;
        width: calc(100vw - 75px);
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        position: relative;

        background-color: rgba(26, 28, 40, .8);
        backdrop-filter: blur(5px);
        z-index: 997;


        z-index: 1;

        .drawer-header {
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 10px;
            margin-top: 6px;

            .arrow-right {
                width: 25px;
                height: 25px;
                background-image: url("@/assets/home/close-white.png");
                background-size: 25px 25px;
                background-repeat: no-repeat;
            }

            .icon-block {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                .iconfont {
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        .drawer-body {
            // padding: 14px 20px;
            height:100%;
            max-height: calc(100% - 60px);
            overflow-y: scroll;

            .nav-cell {
                align-items: flex-start;
                flex-direction: column;

                height: auto !important;

                .link-item {
                    line-height: 19px;
                }

                .drop-dropdown-menu {
                    position: relative !important;
                    height: auto !important;
                    top: 0 !important;
                    padding-top: 8px;
                    background: transparent !important;
                    border-radius: 0 !important;
                    display: flex;
                    grid-row-gap: 8px;
                    flex-direction: column;

                    .drop-dropdown-menu-item {
                        line-height: 19px !important;
                        height: auto !important;
                        padding: 0 0 0 8px !important;
                        // color: #ffffff !important;

                        &.router-link-active {
                            // background:#090A0E;
                            // color: #fff !important;
                        }
                    }

                }

                .whitePaper {
                    width: calc(100% - 40px);
                    height: 40px;
                    background: #51FFA8;
                    border: 0px;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    font-family: BaiJamjuree-Medium, BaiJamjuree;
                    font-weight: 500;
                    color: #2B2E3D;
                    line-height: 40px;
                    margin:33px auto;
                    padding-left:0;
                }
            }

        }

        .login-cell {
            margin-top: 8px;

            .button {
                position: relative;
            }

            .login-link {
                padding: 0;
                background-color: transparent;
                border-radius: 0;
                line-height: 19px;
                font-size: 14px;
                font-family: BaiJamjuree-Regular;
                color: #ffffff;
                border: 0;
            }

        }
    }
}

@media screen and (max-width: (9999px - 1px)) {
    nav {
        /* transform: scale(0.5); */
        padding: 0;
        /* margin-left: -100px; */
        width: calc(100% - 40px);
        margin: 0 auto;
        overflow: auto;
    }

    nav .logo {
        display: none;;
    }

    nav::-webkit-scrollbar {
        width: 0;;
    }

    .nav-cell {
        display: flex;
        font-weight: 500;

        a, div {

            font-size: 14px;
            min-width: calc(100% - 30px);
            min-height: 54px;
            line-height: 54px;
            display: block;
            color: #fff;
            padding-left:20px
        }

        .disabled {
            color: #8C8D92;
        }


    }

    .header-container-nav  .router-link-active {
        background: transparent;
    }
    .router-link-active {
        // background:#090A0E;
        color: #00A3FF !important;
        border-bottom:1px solid #00A3FF;

    }

}
@media screen and (max-width: (768px - 1px)) {
    .home-footer{
     padding: 32px 0;
     flex-direction: column;

    }
    .footer-bottom{
        width: calc(100% - 48px);
    }

    .home-center{
        margin-top:16px;
        margin-bottom:16px;
    }
   .header-container-nav .logo-text{
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        // text-align: left;

    }
    .mobile-nav .header-container-nav {
        padding: 16px 16px;
    }
    .mobile-nav .header-container-nav .down {
        width: 30px;
        height:22px;
        font-size: 20px;
    }
    .flex-content{
        display: none;
    }
    .drawer-wrapper .drawer-container{
        width: 100%;
        max-width:100%;
        height: 550px;
    }
    .logo-infinimos{
        color:#fff;
        a{
            color:#fff!important;
            border:0!important;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
        }
    }
    .drawer-body .nav-cell a{
        margin-bottom: 24px;
        min-height: auto;
        line-height: 22px;
        padding-left:0;
        margin-left:16px;
        min-width: auto;

    }

    .icon-menu {
        width: 32px;
        height: 32px;
        background: url('@/assets/icon-menu-white.png') no-repeat;
        background-size: 100% 100%;
    }
    .mobile-nav  .languageWraper {
        position: absolute;
        bottom: 0;
        right: 0;
        // height: 100vh;
        width: 120px;
        z-index: 99;
        display: flex;
        justify-content: flex-end;

        .drawer-model {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .langList {
            width: 120px;
            height: 230px;
            position: absolute;
            right: 0;
            top: 10px;
            background: rgba(26, 29, 40, 0.7);
            box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(4px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            .lanItem {
                font-size: 20px;
                font-family: BaiJamjuree-Regular, BaiJamjuree;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 20px;

            }

            .lanItemHover {
                color: #1DD79B;
            }
        }
    }
    .drawer-wrapper .drawer-container .drawer-header .arrow-right {
        width: 25px;
        height: 25px;
        background-image: url("@/assets/home/close-white.png");
        background-size: 25px 25px;
        background-repeat: no-repeat;
    }
    .drawer-wrapper .drawer-container .drawer-header  .icon-block {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .iconfont {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .drawer-wrapper .drawer-container .drawer-header{
        height:40px;
        justify-content: space-between;
        color:#fff;
        width: calc(100% - 32px);
        margin:24px auto;
    }

    .wallet-connect{
        margin-left:5px;
    }
    .langList {
        width: 114px;
        height: 230px;
        position: absolute;
        right: 0;
        top: 64px;
        background: rgba(26, 29, 40, 0.7);
        box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(4px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .lanItem {
            font-size: 16px;
            font-family: BaiJamjuree-Regular, BaiJamjuree;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;

        }

        .lanItemHover {
            color: #1DD79B;
        }
    }
    .referral-textarea{
        height: 60px;
        line-height: normal;
    }


}
</style>
<style lang="less" scoped>
.extract-dialog-warp-super{
    border-radius: 6px;
    background-color:transparent;
}

.extract-dialog-warp-super{
    ::v-deep{
        .van-field:focus {
            border-color: #51FFA8;
        }

        .custom-empty-message{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
        }
        .no-date-img{
            width:98px;
            height:64px;
            margin-bottom:17px;
        }

        .dialog-close{
            position: absolute;
            right:0px;
            top:0px;
            width: 40px;
            height: 40px;
        }
        .extract-dialog{
            margin-top:20px;
            border-radius: 6px;
            // height: 334px;
            padding-bottom:5px;
            background-color: #2B2E3D ;
        }
        .dialog-title{
            width:56px;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:0px auto 9px;
            padding-top:22px;
        }
        .dialog-title-img{
            width: 100%;
            height: 100%;
        }
        .extract-input-box{
            width: 259px;
            margin:26px auto 25px;
            border-radius: 6px;
            // border: 1px solid #51FFA8;
        }
        .extract-input-box .van-field__control {
            color: #fff;
        }
        .extract-input-box .van-cell{
            background-color: #191B25 ;
            border-radius: 6px;
        }
        .nav-dialog{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                margin: 0px 8px;
            }
        }
        .extract-dialog-warp{
            min-height: 340px;
            width: calc(100% - 5px);
        }

        .max-text{
            width: 36px;
            height: 21px;
            font-size: 17px;
            font-family: BaiJamjuree-SemiBold, BaiJamjuree;
            font-weight: 600;
            color: #51FFA8;
            line-height: 21px;
            padding-left:17px;
            border-left: 1px solid #e1e1e1cc;
        }
        .extract-dialog-title{
            text-align: center;
            font-size: 22px;
            font-family: BaiJamjuree-SemiBold, BaiJamjuree;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 28px;
            margin-bottom:26px;
        }
        .usdtAmount{
            display: flex;
            color: #ffffff;
            margin-top: -18px;
            margin-left: 35px;
            font-size: 12px;
        }
        .err-extract-text{
            display: flex;
            color: red;
            margin-top: 8px;
            font-size: 12px;
            margin-left: 35px;

        }
        .Authorize-btn{
            width: 259px;
            height: 48px;
            background: #51FFA8;
            border: 0px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:10px auto 30px;
            font-size: 17px;
            font-family: BaiJamjuree-SemiBold, BaiJamjuree;
            font-weight: 600;
            color: #191B25;
            &:disabled{
                background: #8C8D92;
            }
        }
    }

}
.error-extract-input-box{
    border:1px solid red!important;
}
.extract-wrap{
    width: 100%;
}
.invite-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #141A23;
    padding:12px 16px;
    border-radius: 6px;
    width: calc(100% - 32px);
    color:#fff;
}
.your-invitee{
    color:  #FFF;
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;


}
.your-invitee-box{
    // position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;
}
.info-img-box-pendding{
    margin-top:5px;
    margin-left:5px;
}
.total-text{
    color: #00A3FF;
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.342px;
}
.your-Inviter{
    color: #FFF;
    font-family: "Noto Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.38px;
    border-top:1px solid #fff;
    padding-top:16px;
    margin-top:8px;
    width:100%;
}
::v-deep{
    .extract-input-box .van-field__control {
        // color: #fff;
    }
    .extract-input-box .van-cell{
        background-color: #2B2E3D ;
        border-radius: 6px;
        height: 42px;
        max-height:42px;
    }
    .van-field__value{
        max-height:42px;
    }
    .pledge-vant-tabs .van-tabs__nav{
        background: transparent;
    }
    .pledge-vant-tabs .van-tab--active {
        color:#51FFA8;
        font-size: 18px;
        font-family: BaiJamjuree-SemiBold, BaiJamjuree;
        font-weight: 600;

    }
    .pledge-vant-nav-tabs .van-tab--active {
        color:#00A3FF;
        font-size: 12px!important;
        font-family: BaiJamjuree-SemiBold, BaiJamjuree;
        font-weight: 500;

    }
    .pledge-vant-tabs .van-tab {
        color: #00A3FF;
    }
    .pledge-vant-nav-tabs  .van-tab {
        font-size: 12px;
    }
    .custom-amount{
        font-size: 14px;
        font-family: BaiJamjuree-Regular, BaiJamjuree;
        font-weight: 400;
        // color: #00A3FF;
        line-height: 18px;
    }
    .pledge-vant-tabs .van-tabs__line {
        background-color: #00A3FF;
    }
    .extract-wrap table.vgt-table{
        background-color: #262836;
    }
    .extract-wrap .vgt-table thead th{
        background:#0F0F16;
        opacity: 0.8;
        font-size: 14px;
        white-space: nowrap;

        color: #fff;
       padding-left:16px;
       font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.252px;
    }
    .extract-wrap  .custom-status{
        color:#22C348!important;
    }
    .extract-wrap  .custom-status-no{
        color:#F2271C
    }
    .extract-wrap table.vgt-table{
        // border: 1px solid #51FFA8;
        // border-radius: 10px;
        border:none;
    }
    .extract-wrap .vgt-wrap__footer{
        background: #262836;
        border:none;
        // border-top:1px solid #51FFA8;
        font-size: 12px;
        border-radius: 9px;
    }
    .extract-wrap  .vgt-table th.sortable button:before{
        border-top: 0.26042rem solid #fff;
    }
    // .extract-wrap .vgt-inner-wrap{
    //         height: 400!important;;
    //         max-height: 400px!important;
    //         overflow: scroll!important;;
    //      }
        //  .extract-wrap{
        //     max-height: 400px; /* 设置表格容器的最大高度 */
        //     overflow-y: auto; /* 控制垂直方向的滚动条 */
        //  }
         .extract-wrap::-webkit-scrollbar{
            width: 0;
            height: 0;;
            background-color: transparent; /* 设置滚动条轨道的背景颜色为透明 */
         }
    .extract-wrap .vgt-table th.sortable button:after{
        border-bottom: 0.26042rem solid #fff;
    }
    .vgt-responsive{
        border-radius: 9px;
        //  border-radius: 9px 9px 0 0;
    }
    .extract-wrap-super  .vgt-responsive{
        border-radius:9px 9px 0 0;
    }
   .extract-wrap .vgt-inner-wrap{
    box-shadow:none;
   }
   .extract-wrap .vgt-table th.sortable button{
    position: relative;
    top: -4px;
    left: 20px;
    width: auto;
    height: auto;
   }
    .custom-empty-message{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        margin:10vh auto;
    }
    .no-date-img{
        width:98px;
        height:64px;
        margin-bottom:17px;
    }
    .extract-wrap .vgt-table.bordered td, .vgt-table.bordered th{
        border:none;
        font-size: 12px;
        color: #FFFFFF;
    }

    .extract-wrap .vgt-table td{
      padding-left:0.8rem;
      font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.084px;
    }

    .extract-wrap tbody tr{
        border-bottom: 1px solid  #252C32;
        background: #0F0F16;
    }
    .extract-wrap tbody tr:last-child{
        border-bottom:0;
    }
    .custom-col-content{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        letter-spacing: 0.084px;

    }



.extract-dialog-warp .van-cell{
  background-color: #191B25 ;
  border-radius: 6px;
}
}
.position-warp{
    display: flex;
    // padding: 24px;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    border-bottom:1px solid #3F3F3F;
    padding-bottom:24px;
}
.position-box{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:25px;
    width:100%;
}
.position-item{
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 12px;
    color: #fff;
    // width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.084px;
    background: #0C3545;
}
.collect-amount{
    color: #00A3FF;
    /* Headings/Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.252px;
}
.time-list{
    display: flex;
    justify-content: space-between;
}
.time-item{
    width: 100px;
    margin-right:8px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #C8C8C8;
    /* Running Text / Paragraph 14 Reg */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.084px;
}
.time-title{
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    border: 0px solid #DBDBDB;
    background:  #303940;
    color:#00A3FF;
    font-family: Roboto Mono;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.position-amount{
    color:#00A3FF;
    margin-top:4px;
}
.position-item-bottom .position-amount{
    margin-top:0;
}
.pending-item{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.infi-text{
    color: #00A3FF;
    text-align: center;
    /* Headings/Heading 18 Semi Bold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.252px;
}
.position-title{
    color: #C8C8C8;
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.456px;
}
// .info-img-box{
//     position: absolute;
//     right:20px;
//     top:20px;
//     width: 24px;
//     height: 24px;
// }
// .info-img{
//     position: absolute;
//     right:0px;
//     top:0px;
//     cursor: pointer;
// }
.info-img-left{
    position: absolute;
    left:20px;
    top:20px;
    cursor: pointer;
}
.pop-action-first{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    color:  #F6F8F9;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    background:#232332;
    padding:8px 16px;
    width: 235px;
    line-height: 16px; /* 133.333% */
}
.pop-action{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    color:  #F6F8F9;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    background:#232332;
    padding:8px 16px;
    width: 342px;
    line-height: 16px; /* 133.333% */
}
@media screen and (max-width: (768px - 1px)) {
    // .position-warp{
    //     align-items: flex-start;
    // }
    .position-box{
        grid-template-columns: repeat(1, 1fr);
    }
    .position-title {
    color: #FFF;
    /* Headings / Heading 24 Semi Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    letter-spacing: -0.456px;
  }
}
</style>

