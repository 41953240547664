import request from '@/utils/request'

// 用户验证信息
export function getAxiosVerifyMessage(address,data){
    return request({
        url: '/user/getVerifyMessage/'+address,
        method: 'get',
        isNeedToken:true,
        data:data
    })
}
// 获取我的访问token
export function getAxiosAccessToken(data){
    return request({
        url: '/user/getAccessToken',
        method: 'post',
        isNeedToken:true,
        data:data
    })
}

export function verifyMessage(data){
    return request({
        url: '/juno/verifyMessage',
        test:true,
        method: 'post',
        isNeedToken:false,
        data:data
    })
}

// 刷新token操作
export function getAxiosRefreshAccessToken(data){
    return request({
        url: '/user/renewAccessToken?User-Account='+data.address+'&Access-Token='+data.token,
        method: 'get',
        isNeedToken:true,

    })
}

// 获取banner
export function getAxiosBanner(){
    return request({
        url: '/system/getBanner',
        method: 'post',
        isNeedToken:true,
        data:{
            status:1
        }
    })
}


export function getReferral(data){
    return request({
        url: '/user/getReferral',
        method: 'post',
        data:data
    })
}

export function getUser(address){
    return request({
        url: '/user/get',
        method: 'get',
    })
}
export function register(data){
    return request({
        url: '/user/register',
        method: 'post',
        isNeedToken:true,
        data:data
    })
}

export function join(data){
    return request({
        url: '/user/join',
        method: 'post',
        isNeedToken:true,
        data:data
    })
}
export function registerTia(data){
    return request({
        url: '/user/registerTia',
        method: 'post',
        isNeedToken:true,
        data:data
    })
}



export function getSysParams(){
    return request({
        url: '/system/getSysParams',
        method: 'get',
        isNeedToken:true,
    })
}

export function getUserSummary(address){
    return request({
        url: '/user/getUserSummary/'+address,
        method: 'get',
    })
}

// 获取池子tvl
export function getUserOverview(address){
    return request({
        url: '/system/getOverview',
        method: 'get',
    })
}

// 地址获取空投发放信息
export function getUserAirdrop(data){
    return request({
        url: '/user/getAirdrop',
        method: 'post',
        data
    })
}

// invite 获取池子信息
export function getAxiosTeamReward(address){
    return request({
        url: '/user/getTeamReward',
        method: 'get',
    })
}

export function getAxiosRegisterErc(data){
    return request({
        url: '/user/registerErc',
        method: 'post',
        inviteTest:true,
        data
    })
}

export function getAxiosRegisterSol(data){
    return request({
        url: '/user/registerSol',
        method: 'post',
        data
    })
}

export function getAxiosRegisterBep(data){
    return request({
        url: '/user/registerBep',
        method: 'post',
        data
    })
}

export function getAxiosScore(data){
    return request({
        url: '/user/getScore',
        method: 'post',
        data
    })
}
