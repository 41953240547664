export default {
  global:{
    cancel:'Cancel',
    next:'Next',
    confirm:'Confirm',
    Copy:'Copy',
    Rewards:'Rewards',
    Password:'Password',
    Close:'Close',
    No:'No',
    All:'All',
    Yes:'Yes',
    Successful:'Successful',
    Done:'Done',
    Save:'Save',
    SEND:"SEND",
    comingSoon:'Coming soon!',
    DAY:'DAY',
    HOUR:'HOUR',
    MIN:'MIN',
    SEC:'SEC',
    Residue:'Remaining',
    BUY:'BUY',
    BUYED:'Not for sale',
    PlatinumMember:'Platinum Member',
    GlobalPartner:'Global Partner',
    Civilian:'Civilian',
    copySucc:'Copy Success!',
    copyFailed:'Copy Failed!',
    noRecords:'No records',
    useDappExplorer:'Please install the Keplr plugin, or open with Keplr\'s DAPP browser.',
    changeAccount:'You change account to',
    referrals:"referrals",
    prevPage:"Prev",
    nextPage:"Next",
    total1:"",
    total2:" records",
    soldOut:"Sold out",
    MAX:'MAX',
    placeholderInit:'input amount',
    Authorize:'Authorize',
    APR:'APR:',
    Release:'Release',
    Pledge:'Pledge',
    Token:'Token',
    Receive:'Receive',
    unStake:'Unstake',
    Stake:'Stake',
    Withdraw:'Withdraw',
    rewardsHistory:'earnings history',
    investHistory:'Purchase history',
    moreStake:'Stake',
    submit:'Submit',
    Fund:'Fund',
    BSC:'BSC',
    Records:"Records",
    usdtInsufficient:'Insufficient USDT balance',
    beenSent:'Has been sent',
    confirmed:'confirmed',
    failed:'failed',
    approveTimeout:'The authorization limit contract has timed out, please reinitiate the operation.',
    InsufficientApproval:'The authorization amount is insufficient, please increase the authorization amount',
    contractTimeout:'The contract execution result query has timed out. You can query the operation results later to avoid repeated investment',
    createTime:'create time',
    amount:"Amount",
    incomeAmountHistory:'Deposit history',
    usdtBalance:"USDT Balance",
    signTitle: "Prompt",
    signContent: 'Authorization signature is required for secure access',
    signBtn: 'Request Signature',
    signLoadingText: 'Signing in progress, please do not refresh the page',
    claimReward:'claim reward',
    copyAddress:'COPY ADDRESS',
    logOut:'Logout',
    connectWallet:'CONNECT WALLET',
    invite:'Invite',
    inviteFriends:'Invite Friends',
    inviteLink:'Invite Link',
    copyLink:'Copy Link',
    inviteFriend:'Invite Friends',
    copy2Invite:'Copy the link below and share it to invite your friends to join Infinimos',
    Binding:'Binding',
    ReferralCode:'Referral Code',
    referalPlaceHolder:'Input referral wallet address',
    DefaultofficialReferralCode:'Default official Referral Code',
    BindSucc:'copy referral code successfully!',
    BindDuplicate:'You have binded a Referral Code Already!',
    referralCodeEmpty:'Referral code cannot be empty!',
    referralCodeFormat:'Referral code format incorrect!',
    referSelf:'You can not invite yourself!',
    claimYourRewards:'Claim your Rewards',
    collectNow:'Collect Now',
    noClaimableInfi:"No claimable INFI",
    shortLoadingMsg:'Signing in progress, please do not refresh the page',
    loadingMsg:'Please approve the transaction and sign on your wallet. Please do not close or refresh current page until the transaction is completed',
    Contracttimeout:'Contract launch timeout',
    ViewOnBlockchain:'View On Blockchain',
    SignatureSuccessful:"Signature Successful",
    isRegisterUser:'You has not yet registered，Please try logout and connect your wallet again!',
    accumulatedText:'Accumulated Network Invited Reward',
    invitePopText:'The number showing as below is  network staking amount based on your invitation',
    inviteActionText:'Invited Reward= Invitation Reward * 0.8(If you had inviter, if not ,then times 1)',
    GoveranceNode:'Goverance Node',
    LightNode:'Light Node',
    settingsText:'SETTINGS',
    Claim:'Claim',
    Warning:'Warning'
  },
  menu:{
    Exchange:'Exchange',
    fTokenPool: 'F-TOKEN POOL',
    infiPool:'INFI POOL',
    airDrop:'AIR DROP',
    whitePaper:'WHITE PAPER',
    term:'Term of Use',
    privacy:'Privacy Policy',
    disclaimer:'Disclaimer'
  },
exchange:{
  EXCHANGE:'EXCHANGE',
  Your:'Your ',
  unlockedIn:' will be unlocked in',
  Days:'Days',
  Hours:'Hours',
  Minutes:'Minutes',
  Seconds:'Seconds',
  collectUnlockedAtom:'Collect Unclocked ATOM',
  collectUnlockedTia:'Collect Unclocked TIA',
  collectUnlockedEth:'Collect Unclocked ETH',
  collectUnlockedSol:'Collect Unclocked SOL',
  totalUnbonding:'Total Unbonding: ',
  Unlocked:'Unlocked: ',
  multipleTimes:'If you exchanged multiple times from F-tokens to native tokens, you will only see the next period of unlock amount',
  STAKE:'STAKE',
  Stake:'Stake',
  Balance:'Balance',
  receiveLater:'You will receive fTIA within 2 to 3 minutes.',
  receiveLaterFETH:'You will receive fETH within 2 to 3 minutes.',
  receiveLaterFSOL:'You will receive fSOL within 2 to 3 minutes.',
  Unstake:'Unstake',
  UNSTAKE:'UNSTAKE',
  get:'get',
  REDEEM:'REDEEM',
  Exchange:'Exchange',
  Redeem:'Redeem',
  InsufficientBalance:'Insufficient Balance',
  depositMin:'You Can only deposit a minimum of 0.00001 ',
  nextUnlockedAmount:'If you exchanged multiple times, you will only see the next period of unlock amount',
  ETHInsufficient:'ETH Insufficient',
  TIAInsufficient:'TIA Insufficient',
  ATOMInsufficient:'ATOM Insufficient',
  SOLInsufficient:'SOL Insufficient',
  exchangeSucc:'"Your exchange had been processed successfully"',
  CollectRewardSucc: "Your Collect Reward had been processed successfully",
  UnstakeINFISucc: "Your uUnstake INFI had been processed successfully",
  UnstakeSucc:"Your unstake had been processed successfully",
  stakeSucc:"Your Stake had been processed successfully",
  ApproveSuccess:"Your Approve had been processed successfully",
  unlockedfund:"Your unlocked fund had been collected successfully",
  redeemSucc:"Your redeem had been processed successfully",
  modelContent:'Please go to your personal settings to bind the ETH/SOL mapping address to ensure accurate staking and redemption of tokens.',
  modelETHContent:'The ETH wallet address you connected to does not match the bound address. Please switch wallets and try again.',
  modelSOLContent:'The Solana wallet address you connected to does not match the bound address. Please switch wallets and try again',
  GoToSetting:'Go To Setting',
  inconsistent:'The current connection address is inconsistent with the bound address!',
  ethTransitionError:'Please conduct ETH transactions on your PC browser!',
  solTransitionError:'Please conduct SOL transactions on your PC browser!',
  phantomNotInstall:'Solana wallet plugin not detected. Please install Phantom first, then refresh the page and retry!',
  solanaConnected:'Solana Connected',
  solanaDisconnected:'Solana disconnected',
  solanaAccountChanged:'Solana account changed',
  ethConnected:'ETH connected：',
  ethExchangeSuccess:'ETH exchange success!',
  ethExchangeFailed:'ETH exchange failed!',
  transactionTimeout:'Failed to confirm transaction within 60 seconds. The transaction may or may not have succeeded.',
  solanaResult:'SOL exchange:',
  atomInfufficient:'ATOM Insufficient',
  noAccountConnected:'No account connected',
  Solexchange:'Sol exchange:',
  confirmedText:'Confirmed',
  finalizedText:'Finalized'
},
  ftoken:{
    FTokenPool:'F-Token Pool',
    MyPosition:'My Position',
    POOLTVL:'POOL TVL:  ',
    fATOMPOOL:'fATOM POOL',
    fDYMPOOL:'fDYM POOL',
    fETHPOOL:'fETH POOL',
    fSOLPOOL:'fSOL POOL',
    Pool:'POOL',
    HARVEST:'HARVEST',
    STAKEDMOUNT:'STAKED AMOUNT',
    TOTALREWARDS:'TOTAL REWARDS',
    PENDINGREWARD:'PENDING REWARD',
    fTIAPOOL:'fTIA POOL',
    Get:'Get ',
    AboutfATOMonJuno:'About F-token on Juno',
    RewardAPY:'Reward APY',
    ProtocolFee:'Protocol Fee',
    interestCycle:'Interest Cycle',
    RatioofFtoken:'Ratio of F-token',
    InvitationReward:'Invitation Reward',
    minimum:'You Can only deposit a minimum of 0.00001 fATOM',
    fTIAminimum:'You Can only deposit a minimum of 0.00001 fTIA',
    fETHminimum:'You Can only deposit a minimum of 0.00001 fETH',
    fSOLminimum:'You Can only deposit a minimum of 0.00001 fSOL',
    wTomMinimum:'You Can only unStake a minimum of 0.00001 WATOM',
    wTIAMinimum:'You Can only unStake a minimum of 0.00001 wTIA',
    wETHMinimum:'You Can only unStake a minimum of 0.00001 wETH',
    wSOLMinimum:'You Can only unStake a minimum of 0.00001 wSOL',
    calculated:'The reward will calculated every 24 hours',
    FATOMInsufficient:'FATOM Insufficient',
    fAtomStake: 'Your Stake fAtom had been processed successfully',
    fTiaStake:'Your Stake fTIA had been processed successfully',
    fEthStake:'Your Stake fETH had been processed successfully',
    fSolStake:'Your Stake fSOL had been processed successfully',
    withdrawalInavailable:'"No available rewards for withdrawal"',
    harvestfATOM:'Your harvest fATOM had been processed successfully',
    harvestfTIA:'Your harvest fTIA had been processed successfully',
    harvestfETH:'Your harvest fETH had been processed successfully',
    harvestfSOL:'Your harvest fSOL had been processed successfully',
    unStakewAtom:'Your unStake wAtom had been processed successfully',
    unStakewTIA:'Your unStake wTIA had been processed successfully',
    unStakewETH:'Your unStake wETH had been processed successfully',
    unStakewSOL:'Your unStake wSOL had been processed successfully',
    wTIAInsufficient:'wTIA Insufficient',
    fTIAInsufficient: "fTIA Insufficient",
    fETHInsufficient: "fETH Insufficient",
    fSOLInsufficient: "fSOL Insufficient",
    wETHInsufficient:'wETH Insufficient',
    wSOLInsufficient:'wSOL Insufficient',
  },
  infi:{
    InfiPool:'INFI POOL',
    TVL:'TVL',
    YourPosition:'Your Position',
    STAKEDINFI:'STAKED INFI',
    INFIonJuno:'About INFI on Juno',
    hours:'hours',
    UnlockPrinciple:'Unlock Principle of INFI',
    DAYS:'DAYS',
    minimumInfi:'You Can only deposit a minimum of 0.00001 INFI',
    INFimultiple:'if you unstaked your INFi multiple times, you will only see the next unlock period amount of INFI tokens',
    harvestSucc:'Your harvest had been processed successfully',
    INFIInsufficient:'INFI Insufficient',
    infiActionText:'Total Rewards= Personal Staking Reward + Invitation Reward - Contribution Reward -Protocol Fee'
  },
  airdrop:{
    IncentiveAcitivitiy:'Incentive Acitivitiy',
    s1:'We are excited to announce to launch ',
    s2:' Phase 1 Incentive Activity',
    s3:' starting on ',
    s4:' Jan 27, 2024.',
    s5:' sharing two reward Pool of total',
    s6:' 1 Million',
    s7:' INFI Tokens. Criteria: Staking your F-token or Invites your friends.',
    ClaimYourAirdropReward:'Airdrop Dashboard',
    CollectRewardToken:'Collect Reward Token',
    Airdrophistory:'Your Airdrops',
    Activity:'Activity',
    ReleasedDate:'Released Date',
    Eligibility:'Eligibility',
    Amount:'Amount',
    airdropHistory:'View Airdrop History',
    YourJunoAddress:'Your Juno Address',
    provideAddress:'Please provide your juno address as blow to claim your airdrops later',
    ClaimBetween:'Claim Between',
    Airdrop:'Airdrop',
    Dashboard:'Dashboard',
    airdropToken:'If the airdrop token is from ethereum or solana ecosystem, it will be dropped to your bonded address.',
    ScoreLeaderboard:'Score Leaderboard',
    PastAirdrops:'Past Airdrops',
    RewardsYouCanGet:'Rewards You Can Get',
    InputHere:'Input Here'
},
settings:{
  ERC20Address:'Please provide your ERC20 address',
  ConfirmMapping:'Confirm Mapping',
  ConfirmMappingEd:'Confirmed mapping',
  SOLANAAddress:'Please provide your SOLANA address',
  BEP20Address:'Please provide your BEP20 address',
  firstTooltips:'Once you confirm the mapping your address can not be changed!',
  BackToEdit:'Back To Edit',
  addressErrMsg: 'The address format is incorrect!',
  ERC20TextERR: "The ERC20 address has already been bound by another user.",
  solTextERR: "The Solana address has already been bound by another user.",
  bepTextERR: "The BEP20 address has already been bound by another user.",
  settingsETHSuccess: "Successfully set ERC20 address",
  settingsSOLSuccess: "Successfully set Solana address",
  settingsBEPSuccess: "Successfully set BEP20 address"
},
  p:{
    p1: "Acceptance: By using the Infinimos website or application, users agree to abide by the terms and conditions outlined in these Terms of Use.",
    p2: "Eligibility: Users must be at least 18 years of age or older and not be residents of any jurisdiction where using the services provided by Infinimos would be in violation of any applicable law or regulation.",
    p3: "Account registration and security: Users must provide accurate and complete information when creating an account, and are responsible for maintaining the confidentiality of their account credentials.",
    p4: "User conduct: Users are expected to conduct themselves responsibly and ethically, and prohibited activities include abusive language, harassment, or engaging in any illegal activities.",
    p5: "Intellectual property rights: Infinimos retains all intellectual property rights related to the website or application, and users may not reproduce, distribute, modify, or otherwise use any of the intellectual property without prior written consent from Infinimos",
    p6: "Termination: Infinimos reserves the right to terminate user access to the website or application at any time and for any reason, including violations of these Terms of Use, without prior notice.",
    p7: "Modification of terms: Infinimos reserves the right to update or modify these Terms of Use at any time, with or without notice to users. Your continued use of the Infinimos website or application following any changes to these Terms of Use constitutes your acceptance of such changes.",

  },
  p1:{
    p1: "Personal data collected: Collect user information, such as wallet addresses, IP addresses, Telegram profile, and mailing address for promotional materials (if opted in).",
    p2: "Purpose of data collection: Ensure proper functioning of Infinimos, provide services and support, comply with legal obligations, and analyze trends and usage.",
    p3: "Collection methods: Gather personal data through device and usage information, cookies (if used), and information provided by users (e.g., wallet address, email, or Telegram information).",
    p4: "Personal data usage: Utilize collected data for transaction processing, customer support, marketing (if opted in), and detection and prevention of security incidents.",
    p5: "Data protection measures: Ensure an adequate level of protection when transferring personal data to other jurisdictions and share personal data only when necessary with service providers or under specific circumstances.",
    p6: "Data retention: Do not maintain customer accounts or store personally identifiable data, except for certain IP addresses stored by vendors.",
    p7: "Third-party data sharing: Share personal data with vendors, service providers, consultants, and corporate affiliates when necessary, and disclose information for legal or compliance purposes.",

  },
  p2:{
  p1: "You are responsible for conducting your own due diligence on the various UIs through which you access the Infinimos protocol and/or the Smart Contracts to understand the fees and risks they present.",
  p2: "You must ensure that you have a sufficient balance of the applicable Digital Assets stored at your compatible Digital Asset wallet address to complete any transaction on the Infinimos protocol, the Smart Contracts, or the underlying blockchain network before initiating such a transaction.",
  p3: "No individual or entity involved in creating the Infinimos protocol or the Smart Contracts will be liable for any claims or damages whatsoever associated with your use, inability to use, or interaction with other users.",
  p4: "Use of the Infinimos protocol and/or the Smart Contracts may require that you pay a fee, such as network fees and other charges needed to perform a transaction.",
  p5: "Your use of the Infinimos protocol and/or the Smart Contracts involves various risks, including losses while Digital Assets are being supplied and losses due to fluctuating prices of Digital Assets.",

  },
  invitePage:{
    YourInviter:'Your Inviter',
    YourInvitee:'Your Invitee',
    total:'Total',
    address:'Address',
    fAtomStaking:'fAtom Staking',
    fTIAStaking:'fTIA Staking',
    fETHStaking:'fETH Staking',
    fSOLStaking:'fSOL Staking',
    INFIStaking:'INFI Staking',
    date:'Binding Date',
    rank:"Rank",
    Points:'Points',
    altTitle:'Alt airdrop'
  },
  web3:{
    ACTION_REJECTED:"User rejected",
    UNPREDICTABLE_GAS_LIMIT:'Out of gas limit',
    err32000:'Transaction underpriced',
    CALL_EXCEPTION:"Execution Exception, Please Try Again Later",
    CLAIM_ETH_LIMIT:'You can Claim eth once in a day',
    GAS_Insufficient:'Gas insufficient',
  },
  error:{
    ERR_NETWORK:"Network Error!"
  },

}
