/*
 * @Author: 1 1
 * @Date: 2023-02-07 16:12:00
 * @LastEditors: 1 1
 * @LastEditTime: 2023-03-25 23:30:06
 * @FilePath: /node_staking/src/store/modules/app.js
* @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Cookies from 'js-cookie'
import {getLanguage} from "@/lang";
// import { getLanguage } from '@/lang/index'
import {getAxiosVerifyMessage, getAxiosAccessToken, getAxiosRefreshAccessToken, verifyMessage} from "@/api";
import Vue from 'vue';
import CryptoJS from 'crypto-js'
import {
    getAccount,
    Signer
} from "@/utils/cosmMain";
import {SigningCosmWasmClient} from "@cosmjs/cosmwasm-stargate";
import {GasPrice} from "@cosmjs/stargate";
import {ATOM, fATOM, wATOM,INFI,getConfig} from "@/config/network";
import {fromBase64, fromUtf8} from "@cosmjs/encoding";
import {Secp256k1, Secp256k1Signature, Sha256} from "@cosmjs/crypto";
import {sha256} from "ethers/lib/utils";
import {serializeSignDoc} from "@cosmjs/launchpad";
import msg from '@/components/msg';
import i18n from '@/lang'
Vue.use(msg);
const vm = new Vue()
let  overdueTime = process.env.VUE_APP_TOKEN_OVERDUETIME
let curmessage
let userParams
let messageData
const state = {
    device: 'desktop',
    openNav: false,
    account:null,
    language: getLanguage(),
    connectAddress:localStorage.getItem('connectAddress')||'',
    // language: getLanguage(),
    dynamicOverView:null,
    userInfo: null,
    sysParam: null,
    maskVisible:false,
    accountInformation:{
        verifyMessage:'',
        signature:'',
        userAddress:''
    },
    accessToken:'',
    onboard:null,
    isSign:false,
    isLogin:false,
    isStateLoadingSing:false,
    isTransitionLoading:''
};
const mutations = {

    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    TOGGLE_NAV: (state, value) => {
        state.openNav = value
    },
    SET_LANGUAGE: (state, language) => {
        state.language = language
        Cookies.set('language', language)
    },
    SET_CONNECT_ADDRESS:(state, address)=> {
        state.connectAddress = address
    },
    SET_DYNAMIC_OVERVIEW:(state, overview)=>{
        state.dynamicOverView = overview
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_ACCOUNT: (state, account) => {
        state.account = account
    },
    SET_SYS_PARAM: (state, sysParam) => {
        state.sysParam = sysParam
    },
    SET_MASK_VISIBLE: (state, maskVisible) => {
        console.log(maskVisible)
        state.maskVisible = maskVisible
    },
    SET_ACCOUNT_INFORMATION: (state, accountInfo) => {
        state.accountInformation = accountInfo
    },
    SET_ACCESS_TOKEN: (state, accessToken) => {
        state.accessToken = accessToken
    },
    SET_ONBOARD: (state, onboard) => {
        state.onboard = onboard
    },
    SET_SIGN: (state, sign) => {
        state.isSign = sign
    },
    SET_LOADING_SIGN: (state, loadingSign) => {
        state.isStateLoadingSing = loadingSign
    },
    SET_TRANSITION_LOADING: (state, transitionLoading) => {
        state.isTransitionLoading = transitionLoading
    }

};
const  actions = {
    toggleDevice({commit}, device) {
        commit('TOGGLE_DEVICE', device)
    },
    setMaskVisible({commit}, maskVisible) {
        //console.log("SET_USERINFO")
        commit('SET_MASK_VISIBLE', maskVisible)
    },
    setUserInfo({commit}, userInfo) {
        //console.log("SET_USERINFO")
        commit('SET_USERINFO', userInfo)
    },
    setOnboard({commit}, onboard) {
        //console.log("SET_USERINFO")
        commit('SET_ONBOARD', onboard)
    },
    setSysParam({commit}, sysParam) {
        //console.log("SET_USERINFO")
        commit('SET_SYS_PARAM', sysParam)
    },
    setAccount({commit}, account) {
        window.localStorage.setItem('account', account)
        commit('SET_ACCOUNT', account)
    },
    setLanguage({ commit }, language) {
        commit('SET_LANGUAGE', language)
    },
    toggleNav({commit}, value) {
        return  new Promise((resolve,reject)=>{
            commit('TOGGLE_NAV', value)
            resolve()
        })
    },
    setConnectAddress({commit}, address) {
        commit('SET_CONNECT_ADDRESS', address)
    },
    setDynamicOverView({commit}, overview){
        commit('SET_DYNAMIC_OVERVIEW', overview)
    },
    setAccountInformation({commit}, data){
        commit('SET_ACCOUNT_INFORMATION', data)
    },
    setAccessToken({commit}, accessToken){
        commit('SET_ACCESS_TOKEN', accessToken)
    },
    setSign({commit}, sign){
        commit('SET_SIGN', sign)
    },
    setLoadingSign({commit}, sign){
        commit('SET_LOADING_SIGN', sign)
    },
    setTransitionLoading({commit}, transition){
        commit('SET_TRANSITION_LOADING', transition)
    },
    decrypt(state,{str, secret}) {

        // if(!secret) return
        const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
        const cryptoOption = {
          iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        };
        const decryptedStr = CryptoJS.AES.decrypt(str, cryptoKey, cryptoOption).toString(
          CryptoJS.enc.Utf8,
        );
        return decryptedStr;

      },
    encrypt(state,{str, secret}){
        console.log(str, secret)
        const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
        const cryptoOption = {
            iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        };
        const encryptedStr = CryptoJS.AES.encrypt(str, cryptoKey, cryptoOption).toString();
        console.log(encryptedStr,'encryptedStr')
        return encryptedStr;
    },

    async refreshToken({commit,dispatch},accessToken){
        const that = this
         return new Promise(async (resolve, reject) => {
            let account = await getAccount()
            console.log(account,'account==.')

            let storeAccountToken = JSON.parse(localStorage.getItem('storeAccountToken'))
            console.log(storeAccountToken,'messageData==>storeAccountToken')
            const curTokenTimestamp = vm.$dayjs.utc().local().valueOf()
            const newUtcTimestamp = storeAccountToken && storeAccountToken[account] && Number(storeAccountToken[account].startTime) + parseInt(overdueTime/2)*60*1000
            console.log('storeAccountToken==>新',storeAccountToken)
            //  获取当前的账户信息
            if(!storeAccountToken || !storeAccountToken[account]){

                    messageData = await getAxiosVerifyMessage(account)
                   console.log(messageData,'messageData===》新messageData')
                try{
                    // localStorage.setItem('isLoadingSing',true)
                    dispatch('setLoadingSign',true)
                    vm.$showLoading()
                    const options = {
                        prefix: "juno-1",
                        gasPrice: GasPrice.fromString('0.003ujuno'),
                      };
                      console.log('进入CosmSigner==》',getConfig)

                      let signer = await  Signer();
                      // curmessage
                        let client = await SigningCosmWasmClient.connectWithSigner(getConfig.juno.rpcUrl,signer , options)
                    //getConfig.juno.chainId：'juno-1'
                    let signedMessage = await window.keplr.signArbitrary(getConfig.juno.chainId, account, messageData.data);
                    if (!signedMessage) signedMessage = { signature: '', pub_key: { type: '', value: '' } };

                    //此处解析签名是否正确
                    let result = await getAxiosAccessToken({
                        userAddress:account,
                        signature:signedMessage.signature,
                        verifyMessage:messageData.data,
                        pubKey:signedMessage.pub_key.value
                    })
                    if(storeAccountToken && !storeAccountToken[account]){
                        storeAccountToken[account]={address:account,startTime:vm.$dayjs.utc().local().valueOf(),accessToken:result.data.accessToken,verifyMessage:messageData.data,signature:signedMessage.signature}
                    }else{
                        storeAccountToken  = {
                            [account]:{address:account,startTime:vm.$dayjs.utc().local().valueOf(),accessToken:result.data.accessToken,verifyMessage:messageData.data,signature:signedMessage.signature}
                        }

                     }
                     dispatch('setMaskVisible',false)
                    dispatch('setSign',false)
                    vm.$msg.success(i18n.t('global.SignatureSuccessful'), 5000)
                    vm.$hideLoading()
                    // dispatch('setLogin',true)
                    localStorage.setItem('isLogin',true)
                    localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))

                    setTimeout(()=>{
                        console.log('无账户的reload')
                        location.reload()
                    },2000)
                    console.log(result,'result====>')
                    return resolve(result)

                }catch(err){
                    console.log(err,'err',err.code)
                    if(err.code==4001){
                        // this.$msg.error("取消签名！")
                        dispatch('setMaskVisible',true)
                        dispatch('setSign',true)
                        if(storeAccountToken && storeAccountToken[account]){
                            delete storeAccountToken[account]
                            localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
                        }

                    }
                }


            }else{

                console.log('accessToken===>',storeAccountToken)
                // const accessExpriceToken = await dispatch('decrypt',{str:localStorage.getItem('accessToken'),secret: localStorage.getItem('verifyMessage')})
                const accessExpriceToken = storeAccountToken[account].accessToken
                console.log(accessExpriceToken,'accessExpriceToken')
                userParams = {
                    address:account,
                    token:accessExpriceToken
                }
                const curTokenTimestamp = vm.$dayjs.utc().local().valueOf()

                const newUtcTimestamp = storeAccountToken && storeAccountToken[account]&& Number(storeAccountToken[account].startTime) + parseInt(+overdueTime/2)*60*1000

                 const  overdueTimeUtcTimestamp = storeAccountToken && storeAccountToken[account]&& Number(storeAccountToken[account].startTime) + parseInt(+overdueTime)*60*1000
                if(curTokenTimestamp>overdueTimeUtcTimestamp){

                    delete storeAccountToken[account]
                    localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
                    dispatch('refreshToken')
                    return false
                }
                console.log(userParams,'userParams==>')
                const  curDataToken = await getAxiosRefreshAccessToken(userParams)
                console.log(curDataToken,'userParams==>')
                if(!curDataToken || !curDataToken.data) return;
                let curMapStore = {
                   startTime:vm.$dayjs.utc().local().valueOf(),accessToken:curDataToken.data.accessToken
                }
                storeAccountToken[account] = {...storeAccountToken[account],...curMapStore}
                vm.$hideLoading()
                dispatch('setMaskVisible',false)
                 dispatch('setSign',false)
                localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
                setTimeout(()=>{
                    console.log('有账户的reload')
                    location.reload()
                },2000)
                return resolve(curDataToken)
            }
        })
    }
}

 export default {
    namespaced: true,
    state,
    mutations,
    actions
  }

