import axios from 'axios'
import Vue from 'vue';
import Store from '@/store'
import {Toast} from "vant";
import i18n from "@/lang";
import msg from '@/components/msg';
import CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'

import {getAxiosVerifyMessage,getAxiosAccessToken,getAxiosRefreshAccessToken} from "@/api";
import {
    getAccount,
} from "@/utils/cosmMain";
const vm = new Vue();
// create an axios instance
let  overdueTime = process.env.VUE_APP_TOKEN_OVERDUETIME
const service = axios.create({
    timeout: 60 * 1000, // request timeout
})

const cancelToken = axios.CancelToken

const source = cancelToken.source()


let userParams
let curmessage

function decrypt(str, secret) {
    try{
    if(!secret) return
    const cryptoKey = CryptoJS.enc.Utf8.parse(secret);
    const cryptoOption = {
      iv: CryptoJS.enc.Utf8.parse(secret.substring(0, 16)),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };
    const decryptedStr = CryptoJS.AES.decrypt(str, cryptoKey, cryptoOption).toString(
      CryptoJS.enc.Utf8,
    );
    return decryptedStr;
    }catch(err){
        console.log(err,'err')
    }
  }



// request interceptor
service.interceptors.request.use(async config => {
    let BaseUrl = process.env.VUE_APP_URL
        config.headers['X-Frame-Options'] = 'SAMEORIGIN'
    if(config.test==true){
        config.url = "http://localhost:3000" + config.url

    }else{
        console.log(config,'config==>')
        config.url = BaseUrl + config.url
    }


        let storeAccountToken =  JSON.parse(localStorage.getItem('storeAccountToken'))
        let account = Store.getters.account ||localStorage.getItem('account')
        const accessToken = storeAccountToken && storeAccountToken[account] && storeAccountToken[account].accessToken;
        if(Store!=null && Store.getters!=null && Store.getters.account!=null){
            config.headers['user-account'] = account;
            if(accessToken){
                config.headers['access-token'] = accessToken //decrypt(accessToken,verifyMessageStr);
            }
        }


    // config.headers['accessToken'] = verifyMessage
    // config.headers['userAddress'] = Store.getters.account
    // Do something before request is sent
    if (config.method == 'get') {
        config['params'] = config.data;
    }
    // console.log(config,'CONFIG==>')

    if( !config.loading || config.loading!=='hidden' && !config.url.includes('getStakingGraph') && !config.url.includes('getHistory') &&!config.url.includes('getPriceGraph') && !config.url.includes('getRewardGraph')){
        //  vm.$showLoading()
    }

    return config
}, error => {
    // Do something with request error
    //console.log(error) // for debug
    Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    // response => response,
    /**
     * 下面的注释为通过在response里，自定义code来标示请求状态
     * 当code返回如下情况则说明权限有问题，登出并返回到登录页
     * 如想通过xmlhttprequest来状态码标识 逻辑可写在下面error中
     * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
     */
    async response => {
        //  vm.$hideLoading()

        if (response.config.isReturn) return response
        const res = response.data;

        let storeAccountToken = JSON.parse(localStorage.getItem('storeAccountToken'))
        let account = Store.getters.account ||localStorage.getItem('account')
        // 放置失败后 弹层提示从新签名
        if(res.code==403){
            if(storeAccountToken && storeAccountToken[account]){
                delete storeAccountToken[account]
                localStorage.setItem('storeAccountToken',JSON.stringify(storeAccountToken))
            }
            Store.dispatch('app/setMaskVisible',true)
            Store.dispatch('app/setSign',true)

        }


        if (res.code !== 0 &&res.code !== 200 && res.code !== 1000 && res.code!==403 && res.code!==10022 && res.code!==10020 && res.code!==10021) {
            Toast.fail(res.msg)

            return Promise.reject(res)
        } else {
            if(!response.config.url.includes('getRealLpReward')){
                Store.dispatch('app/toggleNav',false)
            }

            return response.data
        }

    },
    error => {
        vm.$hideLoading()
        // store.dispatch('logout')
        //console.log('err' + error) // for debug
        Toast.fail(i18n.t("error."+error.code) || 'Network Error')
        // msg1.error(i18n.t("error."+error.code) || 'Network Error')

        return error
    })
export default service
