import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'exchange',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/exchange.vue')
  },

  {
    path: '/ftoken',
    name: 'ftoken',
    component: () => import(/* webpackChunkName: "exchange" */ '../views/ftoken.vue')
  },
  {
    path: '/infi',
    name: 'infi',
    component: () => import(/* webpackChunkName: "infi" */ '../views/infi.vue')
  },
  {
    path: '/airdrop',
    name: 'airdrop',
    component: () => import(/* webpackChunkName: "infi" */ '../views/airdrop.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/settings.vue')
  },


  {
    path: '*',
    redirect: '/'
  }
]


//解决vue-router重复点击报错问题（this.$router.push()）
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push= function push(location) {
  return originalPush.call(this, location).catch(err => err);
};


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
