export const ATOM = process.env.VUE_APP_TOKEN_ATOM
export const TIA = ""

export const fATOM = process.env.VUE_APP_TOKEN_FATOM
export const wATOM =process.env.VUE_APP_TOKEN_WATOM
export const INFI = process.env.VUE_APP_TOKEN_INFI
export const fTIA =process.env.VUE_APP_TOKEN_FTIA
export const wTIA =process.env.VUE_APP_TOKEN_WTIA

//TODO 地址待更新
export const fETH =process.env.VUE_APP_TOKEN_FETH
export const fSOL =process.env.VUE_APP_TOKEN_FSOL
export const wETH=process.env.VUE_APP_TOKEN_WETH
export const wSOL=process.env.VUE_APP_TOKEN_WSOL


export const UJUNO = "ujuno";
export const UTIA = "utia";

export const getConfig = {
    juno:{
        chainId: 'juno-1',
        chainName: 'Juno',
        addressPrefix: 'juno',
        rpcUrl: 'https://juno-rpc.publicnode.com:443/',
        scanUrl: "https://www.mintscan.io/juno/",
        ethScanUrl:process.env.VUE_APP_SCAN_ETH_URL ,
        solScanUrl: "https://solscan.io/",
        feeToken: 'ujuno',
        stakingToken: 'ujuno',
        coinMap: {
            [UJUNO]: { denom: 'JUNO', fractionalDigits: 6 },
            [ATOM]: { denom: "atom", fractionalDigits: 6 }
        },
        gasPrice:'0.003ujuno',
        fees: {
            // exchange:0,    //gas limit
            // redeem:0,
        },
        atomMintLock:{ //exchange取回锁定期
            num:25,
            unit:"day"
        },
        tiaMintLock:{ //exchange取回锁定期
            num:25,
            unit:"day"
        },
        ethMintLock:{ //exchange取回锁定期
            num:14,
            unit:"day"
        },
        solMintLock:{ //exchange取回锁定期
            num:14,
            unit:"day"
        },
        infiLock:{//infi提取锁定期
            num:7,
            unit:"day"
        },
        mintAddr:process.env.VUE_APP_CONTACT_MINT_ATOM,
        fatomStake: process.env.VUE_APP_CONTACT_STAKE_ATOM,
        mintETHAddr:process.env.VUE_APP_CONTACT_MINT_ETH,
        mintSOLAddr:process.env.VUE_APP_CONTACT_MINT_SOL,
        fethStake:process.env.VUE_APP_CONTACT_STAKE_ETH,
        fsolStake:process.env.VUE_APP_CONTACT_STAKE_SOL,
        infiStake:process.env.VUE_APP_CONTACT_STAKE_INFI,
        airdropAddr:process.env.VUE_APP_CONTACT_AIRDROP
    },
    celestia:{
        chainId: "celestia",
        chainName: 'Celestia',
        addressPrefix: "celestia",
        rpcUrl: "https://celestia-rpc.publicnode.com:443",
        scanUrl: "https://www.mintscan.io/celestia/",
        feeToken: 'ujuno',
        stakingToken: 'ujuno',
        denom:"utia",
        coinMap: {
            [UTIA]: { denom: 'TIA', fractionalDigits: 6 },
        },
        mintAddr:process.env.VUE_APP_CONTACT_MINT_TIA,
        ftiaStake: process.env.VUE_APP_CONTACT_STAKE_TIA,
        gasPrice: "0.015utia",

    },
}
