import Vue from 'vue'
import './styles/index.less';

import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './permission'

import $loading from '@/components/loading'

import msg from '@/components/msg';
Vue.use(msg);
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import i18n from '@/lang'
import Vant from 'vant/lib'
import 'vant/lib/index.css'
import VueGoodTablePlugin from 'vue-good-table';
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import VueLoadmore from 'vuejs-loadmore';

import mypagination from "@/components/CustomPagination/index"
import countTo from 'vue-count-to';


import { sleep ,RetainDecimal } from './utils/common';


// import filters from './utils/filters'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

Vue.filter('RetainDecimal',RetainDecimal)

Vue.component('v-pagination', mypagination)

Vue.component('v-count-to', countTo)
Vue.use(VueLoadmore, {
  lang: 'zh-cn'
})

Vue.prototype.$sleep = sleep
Vue.prototype.$dayjs = dayjs
Vue.config.productionTip = false
Vue.prototype.$AccessToken = null

Vue.use(Vant)
Vue.use(VueGoodTablePlugin);
Vue.use($loading)
Vue.config.errorHandler = function (err, vm, info) {
  let data={

  }
  console.error('Vue错误:', err);
  uploadDebug(data)
  // handle error
  //`err`是js错误栈信息，可以获取到具体的js报错位置。
  //`vm` vue实例
  //`info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 只在 2.2.0+ 可用
}
window.addEventListener('unhandledrejection', (event)=>{
  console.log('Browser extension error unhandledrejection:', event);
});
// 全局设置点击遮罩层不关闭 Dialog
// ElementUI.Dialog.props.closeOnClickModal.default = false
// import VueGtag from 'vue-gtag'
// Vue.use(VueGtag, {
//   config: { id: "G-TMQK125CR" }
// }, router);
// 显示弹框时，设置页面滚动条仍然可以滚动

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
